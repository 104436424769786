/* global AppState */
/**
 * A component to place hotspots on an image in a content block
 */
import $ from 'jquery';
import Factory from 'components/utils/Factory';
import createLogger from 'components/logger/Logger';
import EventTypes from 'components/EventTypes';
import device from 'components/utils/device';
import SideNavigationResizeHandler from 'components/sidenavigation/ResizeHandler';
import { gsap, CSSPlugin } from 'gsap/all';
import 'jquery.resizeEvents';
import 'jquery.scrollEvents';
import 'jquery.extendPrototype';
import 'components/domutils/Element.jQuery';
import 'components/utils/CurrentBreakpoint';

const Logger = createLogger('hotspots');
gsap.registerPlugin(CSSPlugin);

const $window = window.jq;

function Hotspot($element) {
  Logger.measure(this, ['positionHotspots']);

  this.$hotspotContainer = $element;
  this.$hotspots = this.$hotspotContainer.find('.js-hotspot');
  this.$contentBlockPicture = this.$hotspotContainer.siblings('.js-has-hotspots');
  this.$contentBlockImage = this.$contentBlockPicture.find('.js-has-hotspots-image');

  if (!this.$contentBlockImage.length) {
    return false;
  }

  this.fitMode = (this.$contentBlockPicture.attr('data-position-type') || '').toLowerCase();
  this.image = this.$contentBlockImage.get(0);
  this.lazyLoadPicture = this.$contentBlockPicture.hasClass('js-lazyLoadPicture');

  this.positionHotspots();
  this.bindEvents();
}

Object.assign(Hotspot.prototype, {
  animationProperties: {
    duration: 300,
    easing: 'easeInOutCirc',
  },
  cachedSizes: {},
  lazyLoadPicture: false,
  hotspotAlign: 'right',
  hotspotPosition: 0,
  hotspotOffsetFromSide: 5,
  isReady: false,
  isInitialPositioning: true,

  bindEvents() {
    let eventName = 'resizeUpdate resizeEnd';
    if (device.osName === device.IOS) {
      eventName += ' orientationchange';
    }

    const listener = this.positionHotspots.bind(this);
    $window.on(eventName, listener).on(EventTypes.SIDENAV_ANIMATION_COMPLETE, listener);

    this.$contentBlockPicture.on(EventTypes.SCALE_ELEMENT_IN_CONTAINER_COMPLETE, listener);
    this.$contentBlockImage.on('load', listener);

    document.jq.on(EventTypes.HOTSPOTS_FORCE_ENABLE, this.enableHotspotHandler.bind(this));

    this.initIntersectionObserver();
  },

  initIntersectionObserver() {
    const options = {
      rootMargin: '100px 0px 100px 0px',
    };

    const intersectionObserver = new IntersectionObserver((entries, observer) => {
      if (!entries.length) {
        return;
      }
      const subject = entries[0];
      if (subject.isIntersecting) {
        this.enableHotspotHandler();
        observer.unobserve(subject.target);
      }
    }, options);
    intersectionObserver.observe(this.$hotspotContainer.get(0));
  },

  /**
   * Enable (fade-in) hotspots on resize and scroll
   * @param {Boolean} forceEnable - skip in viewport check and enable hotspots when set to true
   */
  enableHotspotHandler() {
    const $hotspotList = this.getCurrentHotspotList();
    if (!$hotspotList.data('is-fade-in-complete')) {
      this.showHotspots($hotspotList);

      // to make sure not to check this list again
      $hotspotList.data('is-fade-in-complete', true);
    }
  },

  /**
   * Returns the breakpoint matching hotspot list
   * @return {jQuery Object}
   */
  getCurrentHotspotList() {
    let { currentBreakpoint } = AppState;

    if (currentBreakpoint === 'large' || currentBreakpoint === 'xlarge') {
      currentBreakpoint = 'medium';
    } else if (!currentBreakpoint) {
      currentBreakpoint = 'xxsmall';
    }

    return this.$hotspotContainer.find(`.hotspotsList--${currentBreakpoint}`);
  },

  /**
   * Show hotspots within the current hotspot list
   */
  showHotspots($hotspotList) {
    $hotspotList.children().each(function (index) {
      gsap.to($(this), {
        duration: 0.25,
        css: { opacity: 1 },
        delay: 0.25 * index,
      });
    });
  },

  /**
   * Get image width or height
   * @param  {String} property Either "width" or "height"
   * @return {Number}
   */
  getImageSize(property) {
    let result = 0;
    const { image } = this;
    const ucProperty = capitalizeFirstLetter(property);

    if (image) {
      const scaleX = image.width / image.naturalWidth;
      const scaleY = image.height / image.naturalHeight;
      let scale;

      switch (this.fitMode) {
        case 'contain':
          scale = Math.min(scaleX, scaleY);
          break;
        case 'cover':
          scale = Math.max(scaleX, scaleY);
          break;
        default:
          result = image[property] || image[`client${ucProperty}`] || image[`natural${ucProperty}`];
      }

      if (scale) {
        result =
          (image[`natural${ucProperty}`] || image[property] || this.cachedSizes[property] || 0) *
          scale;
      }
    }

    if (result) {
      this.cachedSizes[property] = result;
    } else {
      result = image[property];
    }

    return result || this.cachedSizes[property];
  },

  /**
   * Return height of image on screen
   * @return {Number} Width of image
   */
  getImageHeight() {
    return this.getImageSize('height');
  },

  /**
   * Return width of image on screen
   * @return {Number} Width of image
   */
  getImageWidth() {
    return this.getImageSize('width');
  },

  positionHotspots() {
    if (this.lazyLoadPicture) {
      this.$contentBlockPicture.one(
        EventTypes.LAZY_LOADED,
        function () {
          this.sizeHotspotContainer(this.isReady);
          this.positionHotspotPoints();
        }.bind(this)
      );
    } else {
      this.sizeHotspotContainer(this.isReady);
      this.positionHotspotPoints();
    }
  },

  /**
   * Position the hotspots on the image
   * The width of the image is based on the position. It will take up the
   * left over space. Extra calculations are done if the image is smaller
   * then the content block, to give the hotspot more space.
   */
  positionHotspotPoints() {
    let imageOffset = 0;
    const imageWidth = this.getImageWidth();
    const containerWidth = this.$contentBlockPicture.width();

    if (imageWidth === 0 || containerWidth === 0) {
      return;
    }

    if (containerWidth > imageWidth) {
      imageOffset = (this.$contentBlockImage.offset().left / imageWidth) * 100;
    }

    this.$hotspots.each((i, element) => {
      requestAnimationFrame(() => {
        const $hotspot = element.jq;
        const align = $hotspot.attr('data-align') || this.hotspotAlign;
        const offsetTop =
          parseFloat($hotspot.attr('data-position-top'), 10) || this.hotspotPosition;
        const offsetLeft =
          parseFloat($hotspot.attr('data-position-left'), 10) || this.hotspotPosition;
        const width =
          align === 'left'
            ? imageOffset + offsetLeft - this.hotspotOffsetFromSide
            : imageOffset - offsetLeft + 100 - this.hotspotOffsetFromSide;

        // if both offsetTop and offsetLeft are 0
        // then this hotspot is part of a list positioned
        // at the bottom (marc newson style)
        if (offsetTop === 0 && offsetLeft === 0) {
          $hotspot.closest('.js-hotspotsList').addClass('hotspotsList--bottomList');
        } else {
          $hotspot.css({
            left: `${offsetLeft}%`,
            top: `${offsetTop}%`,
            width: `${width}%`,
          });
        }
      });
    });

    // show hotspots one by one
    if (this.isInitialPositioning) {
      this.enableHotspotHandler();
      this.isInitialPositioning = false;
    }
  },

  ready() {
    this.isReady = true;
  },

  resetContainerPosition() {
    this.$hotspotContainer.removeAttr('style');
  },

  /**
   * Position the hotspot container over the image
   *
   * This needs to be done because the image is not the same size as the
   * contentBlock.
   */
  sizeHotspotContainer() {
    requestAnimationFrame(() => {
      const containerWidth = this.$contentBlockPicture.width();
      const containerHeight = this.$contentBlockPicture.height();
      const imageHeight = this.getImageHeight();
      const imageWidth = this.getImageWidth();

      if (imageHeight && imageWidth && containerWidth && containerHeight) {
        let marginLeft;
        let marginTop;

        switch (this.fitMode) {
          case 'cover':
          case 'contain':
            marginLeft = Math.round((containerWidth - imageWidth) / 2);
            marginTop = Math.round((containerHeight - imageHeight) / 2);
            break;
          default:
            marginLeft = parseInt(this.$contentBlockImage.css('margin-left'), 10);
            marginTop = parseInt(this.$contentBlockImage.css('margin-top'), 10);
        }

        this.$hotspotContainer
          .css({
            height: imageHeight,
            width: imageWidth,
            marginLeft,
            marginTop,
          })
          .addClass('is-positioned');

        if (!this.isReady) {
          this.ready();
        }
      }
    });
  },
});

/**
 * Uppercase first letter of a string
 * @param  {String} str
 * @return {String}
 */
function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default Factory.create(Hotspot, {
  onFirstInstanceCreated: SideNavigationResizeHandler.initialize.bind(SideNavigationResizeHandler),
});
