/* global AppSettings */
/**
 * Contains logic for the grid carousel, which is optimised for touch devices
 *
 * This carousel is also used as the base for the GridCarousel which can be
 * created in the CMS
 *
 */
import createLogger from 'components/logger/Logger';
import $ from 'jquery';
import Factory from 'components/utils/Factory';
import LogLevels from 'components/logger/LogLevels';
import EventTypes from 'components/EventTypes';
import matchBreakpoint from 'components/utils/matchBreakpoint';
import device from 'components/utils/device';
import SideNavigationResizeHandler from 'components/sidenavigation/ResizeHandler';
import triggerResizeEvent from 'components/utils/triggerResizeEvent';
import fixWebkitStretchedImages from './utils/fixWebkitStretchedImages';
import AnimationTypes from './AnimationTypes';
import isElementVisible from 'components/utils/isElementVisible';
import onWindowLoaded from 'components/utils/onWindowLoaded';
import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
import 'jquery.resizeEvents';
import 'jquery.extendPrototype';
import 'jquery.bindTransitionEnd';
import 'components/domutils/Element.jQuery';

const Logger = createLogger('GridCarousel');

const $document = document.jq;
const $window = window.jq;

const navigationContainerTemplate =
  '<div class="gridCarousel-navigation">' +
  '<ul class="gridCarousel-navigation-list"></ul>' +
  '</div>';

const navigationItemTemplate =
  '<li class="gridCarousel-navigation-list-item js-gridCarousel-navigation-list-item"></li>';

const prevNextTemplate =
  '<span class="gridCarousel-prevnext gridCarousel-prevnext--prev js-prevSlide"></span>' +
  '<span class="gridCarousel-prevnext gridCarousel-prevnext--next js-nextSlide"></span>';

function GridCarousel($element, settings) {
  $.extend(this, settings);
  this.slideIntervalDurations = [];
  this.$gridCarousel = $element;
  this.$gridCarouselOverlay = $();
  if ($element.get(0).querySelector('.js-contentBlock-body')) {
    this.$gridCarouselOverlay = $element.siblings('.js-contentBlock-body');
  }

  // override defaults and js settings with data attributes
  const data = this.$gridCarousel.data();
  if (data.animation) {
    this.animation = data.animation;
  }

  this.hasAnimation = this.animation !== AnimationTypes.NO_TRANSITION;
  this.group = data.group;

  if (!this.showNavigation) {
    this.showNavigation = String(data.showPagination) === 'true';
  }

  this.showPrevNext = String(data.showControls) === 'true' || this.showPrevNext;
  this.autoSlide = String(data.autoslide) === 'true' || this.autoSlide;

  // Remember setting to be able to enable it again when needed
  this.autoSlideEnabled = this.autoSlide;
  this.updateAutoSlide();

  const slideIntervalDurationData = data.slideIntervalDuration
    ? data.slideIntervalDuration.toString().split(',').map(Number)
    : this.slideIntervalDuration;

  if (slideIntervalDurationData.length) {
    this.slideIntervalDurations = slideIntervalDurationData;
  }

  const transitionDurationData = parseInt(data.transitionDuration, 10);
  if (!isNaN(transitionDurationData)) {
    this.transitionDuration = transitionDurationData;
  }

  this.componentName += `#${this.numInstance}`;
  if (this.group) {
    this.componentName += ` [${this.group}]`;
  }

  this.eventNamespace += this.numInstance;

  let transitionClass;
  switch (this.animation) {
    case AnimationTypes.SLIDE_OFF:
    case AnimationTypes.SLIDE_OVER:
      if (device.hasTouch) {
        transitionClass = () => {
          return import(
            /* webpackChunkName: "transition--Slide" */ 'components/carousels/transitions/Slide'
          );
        };
      } else {
        transitionClass = () => {
          return import(
            /* webpackChunkName: "transition--SlideOffOver" */ 'components/carousels/transitions/SlideOffOver'
          );
        };
      }
      break;
    case AnimationTypes.FADE:
    case AnimationTypes.NO_TRANSITION:
      transitionClass = () => {
        return import(
          /* webpackChunkName: "transition--CSS" */ 'components/carousels/transitions/CSS'
        );
      };
      break;
    case AnimationTypes.REVEALING_CLOCK:
      transitionClass = () => {
        return import(
          /* webpackChunkName: "transition--RevealingClock" */ 'components/carousels/transitions/RevealingClock'
        );
      };
      break;
    case AnimationTypes.SLIDE:

    /* falls through */
    default:
      transitionClass = () => {
        return import(
          /* webpackChunkName: "transition--Slide" */ 'components/carousels/transitions/Slide'
        );
      };
      break;
  }

  typeof transitionClass === 'function' &&
    transitionClass().then(transitionClassFunction => {
      this.onTransitionLoaded.call(this, transitionClassFunction.default);
    });

  Logger.debug(this.componentName, 'Created', this);
}

$.extendPrototype(GridCarousel, {
  // Currently active slide index
  activeIndex: 0,

  // Animation type (should be in AnimationTypes object)
  animation: AnimationTypes.SLIDE,

  // When true GridCarousel is automatically destroyed and initialized based on whether it's visible or not
  autoDestroyAndInitialize: true,

  // Should the carousel start automatically
  autoSlide: false,

  // setTimeout id for autoSlide
  autoSlideIntervalId: undefined,

  // Used for logging
  componentName: 'GridCarousel',

  // Event namespace (is incremented by number of instances so it's unique for every instance)
  eventNamespace: '.GridCarousel',

  // If set GridCarousels within the same group keep eachother in sync
  group: undefined,

  // Is true when this.animation is not AnimationTypes.NO_TRANSITION
  hasAnimation: true,

  // Is true when a backwards animation is available
  hasReverseAnimation: false,

  // Has GridCarousel been initialized
  initialized: false,

  // Is gridCarousel running (true) or paused (false)
  running: true,

  // Show dot navigation
  showNavigation: false,

  // Show next/previous buttons
  showPrevNext: false,

  // Duration between autoSlide calls
  slideIntervalDuration: [4000],

  slideIntervalDurations: [],

  // Selector for thumbnails
  thumbnailSelector: undefined,

  // Duration of transition animation
  transitionDuration: 600, // ms

  // a plugin that manages the transition between slides
  transitionManager: undefined,

  // Should events be dispatched (is used for temporarily disabling events when triggered via group)
  triggerEventsEnabled: true,

  //
  isAnimating: false,

  // ugly way to make sure the transition doesn't cut off time from the slide duration
  isFirstInterval: true,

  /**
   * Initialize transition and grid carousel when transition is done loading
   * @param {Object} Transition
   */
  initTransition(Transition) {
    this.transitionManager = new Transition(this);

    if (this.autoDestroyAndInitialize) {
      requestAnimationFrame(this.initializeAutoDestroyAndInitialize.bind(this));
    } else {
      this.initialize();
    }
  },

  /**
   * Create slideshow navigation indicator
   */
  addNavigation() {
    if (this.transitionManager.numSlides < 2) {
      this.showNavigation = false;
      return false;
    }

    this.$gridCarousel.append(navigationContainerTemplate);

    const $gridCarouselContainer = this.$gridCarousel.find('.gridCarousel-navigation-list');
    let itemsHtml = '';
    for (let i = 0; i < this.transitionManager.numSlides; i++) {
      itemsHtml += navigationItemTemplate;
    }
    $gridCarouselContainer.html(itemsHtml);

    this.$navigationItems = this.$gridCarousel.find('.js-gridCarousel-navigation-list-item');
    this.bindNavigationEvents();
    this.setActiveNavigationItem(0);
  },

  /**
   * Create slideshow navigation indicator
   */
  addPrevNextNavigation() {
    if (this.transitionManager.numSlides < 2) {
      this.showPrevNext = false;
      return false;
    }

    this.$gridCarousel.append(prevNextTemplate);
    this.$prevSlideButton = this.$gridCarousel.find('.js-prevSlide');
    this.$nextSlideButton = this.$gridCarousel.find('.js-nextSlide');
    this.bindPrevNextEvents();
  },

  /**
   * Bind events
   */
  bindEvents() {
    if (this.animation === AnimationTypes.SLIDE || this.showPrevNext) {
      $window.on(`resizeWidthEnd${this.eventNamespace}`, this.onResizeUpdateAutoSlide.bind(this));
    }

    if (device.hasMouse) {
      this.$gridCarousel.hover(this.pause.bind(this, true), this.resume.bind(this, true));
      this.$gridCarouselOverlay.hover(this.pause.bind(this, true), this.resume.bind(this, true));
    }

    // Bind listeners for group events
    if (this.group) {
      $document
        .on(
          EventTypes.CAROUSEL_PAUSED + this.eventNamespace,
          this.onGroupEvent.bind(this, this.pause.bind(this, true))
        )
        .on(
          EventTypes.CAROUSEL_RESUMED + this.eventNamespace,
          this.onGroupEvent.bind(this, this.resume.bind(this, true))
        )
        .on(
          EventTypes.CAROUSEL_SLIDE_CHANGE + this.eventNamespace,
          this.onGroupEvent.bind(this, this.onGroupChangeSlide)
        );
    }

    // pause / resume autoSlide carousels
    // when the window / tab is (in)active
    $document.on('visibilitychange', event => {
      if (document.visibilityState === 'visible') {
        this.resume();
      } else if (document.visibilityState === 'hidden') {
        this.pause();
      }
    });

    $document.on(EventTypes.ZOOM_SHOW, () => (this.autoSlide = false));
  },

  /**
   * Bind events for dotted navigation
   */
  bindNavigationEvents() {
    this.$navigationItems.on(`vclick${this.eventNamespace}`, this.onNavigationItemClick.bind(this));
  },

  /**
   * Bind events on next/previous buttons
   */
  bindPrevNextEvents() {
    this.$prevSlideButton.on(
      `vclick${this.eventNamespace}`,
      function (event) {
        event.stopImmediatePropagation();
        if (!this.isAnimating) {
          this.gotoPreviousSlide(true);
        }
      }.bind(this)
    );

    this.$nextSlideButton.on(
      `vclick${this.eventNamespace}`,
      function (event) {
        event.stopImmediatePropagation();
        if (!this.isAnimating) {
          this.gotoNextSlide(true);
        }
      }.bind(this)
    );
  },

  dataLayerThumbnailClick(index) {
    const image = document.querySelector(`.js-gridCarousel-item[data-index="${index}"] img`);

    if (!image) {
      return;
    }

    document.jq.trigger(AnalyticsEventTypes.PDP_THUMBNAIL_CLICK, {
      action: `click arrow ${index + 1}`,
      label: image.dataset.imgType,
      materialNumber: document.querySelector('.js-productDetailSection').dataset.sku,
    });
  },

  /**
   * Bind thumbnail events
   */
  bindThumbnailEvents() {
    document.jq
      .on(EventTypes.PDP_IMAGE_FAILED_LOADING, this.removeSlide.bind(this))
      .on(EventTypes.PDP_THUMBNAIL_FAILED_LOADING, this.removeSlide.bind(this));
    this.$thumbnails.on(`vclick${this.eventNamespace}`, this.onThumbnailClick.bind(this));
    fixWebkitStretchedImages(this.$gridCarousel);
  },

  /**
   * Animate to specified slide
   *
   * @param {int} index Slide index
   */
  changeSlide(index) {
    if (this.transitionManager.numSlides <= 1) {
      Logger.warn(
        this.componentName,
        'changeSlide: there are not enough slides in this carousel:',
        this.transitionManager.numSlides
      );
      return;
    }

    if (isNaN(index)) {
      Logger.warn(this.componentName, 'changeSlide: index is not a number:', index);
      return;
    }

    const maxIndex = Math.max(this.transitionManager.numSlides - 1, 0);

    if (index > maxIndex) {
      index %= maxIndex;
    } else if (index < 0) {
      index = maxIndex + index;
    }

    // Maximize index between 0 and maxIndex
    index = Math.max(0, Math.min(maxIndex, index));

    this.previousActiveIndex = this.activeIndex;
    this.activeIndex = index;

    if (this.activeIndex === this.previousActiveIndex) {
      return;
    }

    const movingForward = this.isForward(this.previousActiveIndex, this.activeIndex);

    if (Logger.shouldLog(LogLevels.TRACE)) {
      Logger.trace(
        this.componentName,
        'changeSlide',
        'from',
        this.previousActiveIndex,
        'to',
        this.activeIndex,
        movingForward ? 'forwards' : 'backwards',
        'using',
        this.animation,
        'animation'
      );
    }
    this.isAnimating = true;
    this.transitionManager.changeSlide(movingForward);

    if (this.animation !== AnimationTypes.SLIDE) {
      if (this.showNavigation) {
        this.setActiveNavigationItem(this.activeIndex);
        this.resetSlideInterval();
      } else if (this.autoSlide) {
        this.resetSlideInterval();
      }
    }

    this.triggerEvent(EventTypes.CAROUSEL_SLIDE_CHANGE, {
      index: this.activeIndex,
    });

    // unblock actions during transition
    setTimeout(
      function () {
        this.isAnimating = false;
      }.bind(this),
      this.transitionDuration
    );
  },

  /**
   * Destroy carousel
   */
  destroy() {
    Logger.debug(this.componentName, 'destroy');

    this.pause();
    this.activeIndex = this.numItems = 0;
    this.running = true;

    this.transitionManager.destroy();

    this.$thumbnails.off(this.eventNamespace);

    $document.off(this.eventNamespace);
    $window.off(this.eventNamespace);

    if (this.showNavigation) {
      this.$gridCarousel.find('.gridCarousel-navigation').remove();
      this.$navigationItems.off(this.eventNamespace);
    }

    this.initialized = false;
  },

  /**
   * Go to next slide
   * @param {Boolean} userInitiated Indicated if triggered by user interaction
   */
  gotoNextSlide(userInitiated) {
    if (!userInitiated && this.isFirstInterval) {
      this.isFirstInterval = false;

      // this is to prevent that the transition duration can be longer than the slide duration.
      const oldValues = this.slideIntervalDurations;
      oldValues.forEach(
        function (duration) {
          duration += this.transitionDuration;
          this.slideIntervalDurations.push(duration);
        }.bind(this)
      );
    }

    Logger.trace(this.componentName, 'gotoNextSlide', this.$gridCarousel);
    this.gotoSlide(
      (this.activeIndex + 1) % (this.transitionManager.numSlides || 1),
      !!userInitiated
    );
  },

  /**
   * Go back one slide
   * @param {Boolean} userInitiated Indicated if triggered by user interaction
   */
  gotoPreviousSlide(userInitiated) {
    Logger.trace(this.componentName, 'gotoPreviousSlide', this.$gridCarousel);
    this.gotoSlide(
      this.activeIndex === 0 ? this.transitionManager.numSlides - 1 : this.activeIndex - 1,
      !!userInitiated
    );
  },

  /**
   * Goto specified slide (used from gotoNextSlide and gotoPreviousSlide methods)
   * @param  {Int} index              Slide index
   * @param  {Boolean} userInitiated  When true autoSlide is disabled on touch devices
   */
  gotoSlide(index, userInitiated) {
    const maxNumSlides = this.transitionManager.$gridCarouselItems.eq(index - 1).next().length < 1;

    // Disable autoSlide when interacting with carousel on touch device
    if (device.hasTouch) {
      if (userInitiated || (AppSettings.isEnableAutoImageSlide && maxNumSlides)) {
        this.autoSlide = false;
      }
    }

    this.resetSlideInterval();
    this.changeSlide(index);
    userInitiated && this.dataLayerThumbnailClick(index);
  },

  /**
   * Initialize carousel (this is not always called on creation)
   */
  initialize() {
    Logger.info('Initialized');

    if (this.initialized) {
      Logger.error(this.componentName, 'Cannot initialize already initialized carousel.');
      return;
    }

    Logger.debug(this.componentName, 'initialize', this.$gridCarousel);

    this.transitionManager.initialize();

    this.$thumbnails = $(this.thumbnailSelector);
    this.updateIndexes();
    this.removeEmptySlides();

    if (this.$thumbnails.length) {
      this.bindThumbnailEvents();
    }

    if (this.showNavigation) {
      this.addNavigation();
    }

    if (this.showPrevNext) {
      this.addPrevNextNavigation();
    }

    this.bindEvents();
    this.resetSlideInterval();
    this.updateIndexes();

    this.initialized = true;
    this.triggerEvent(EventTypes.CAROUSEL_READY);
  },

  /**
   * Bind event listener for `autoDestroyAndInitialize` functionality
   */
  initializeAutoDestroyAndInitialize() {
    $window.on(`resizeEnd${this.eventNamespace}-destroy`, this.onCheckVisibility.bind(this));
    this.onCheckVisibility();
  },

  /**
   * Get direction for transition, if new index is larger then previous index
   * we consider the carousel to go forward (return true).
   *
   * Exceptions:
   * - When moving from last slide to first slide it's considered as going forward (return true)
   * - When moving from first slide to last slide it's considered as going backwards (return false)
   *
   * @param  {Int}    from  Start index
   * @param  {Int}    to    Target index
   * @return {Number}       true if forward, false if backward
   */
  isForward(fromIndex, toIndex) {
    const maxIndex = this.transitionManager.numSlides - 1;

    return (
      (toIndex > fromIndex || (toIndex === 0 && fromIndex === maxIndex)) &&
      !(fromIndex === 0 && toIndex === maxIndex)
    );
  },

  /**
   * Load and set all product images on their respective slides
   * @param {int} index The slide index that needs to be loaded
   */
  loadGridCarouselImage(index) {
    this.transitionManager.loadGridCarouselImage(index);
  },

  /**
   * Initialize carousel when visible, destroy when invisible
   */
  onCheckVisibility() {
    const wasVisible = this.isVisible;

    requestAnimationFrame(() => {
      this.isVisible = isElementVisible(this.$gridCarousel.get(0));

      requestAnimationFrame(() => {
        if (this.isVisible !== wasVisible) {
          Logger.debug(this.componentName, 'is', this.isVisible ? 'visible' : 'hidden');

          if (this.autoDestroyAndInitialize) {
            if (this.isVisible && !this.initialized) {
              this.initialize();
            } else if (!this.isVisible && this.initialized) {
              this.destroy();
            }
          }
        }
      });
    });
  },

  /**
   * Handle next event
   * @param {jQuery.Event} event
   * @param {Object} eventData Object containing data beloning to event
   */
  onGroupChangeSlide(event, eventData) {
    if (eventData && eventData.index !== undefined) {
      this.resetSlideInterval();
      this.changeSlide(eventData.index);
    }
  },

  /**
   * Filter event, only call callback when the initiator is not this and group is the same as the group this carousel belongs to.
   * @param {Function} callback Method to call if group matches and initiator is not self
   */
  onGroupEvent(callback, event, eventData) {
    const args = Array.from(arguments);

    // Remove callback from arguments array
    args.shift();

    if (
      typeof eventData === 'object' &&
      eventData.group === this.group &&
      eventData.initiator !== this
    ) {
      Logger.trace(this.componentName, 'onGroupEvent', event.type, eventData);

      if (eventData.numSlides !== this.transitionManager.numSlides) {
        Logger.warn(
          this.componentName,
          'onGroupEvent: This carousel does not have the same amount of slides as initiator:',
          this.transitionManager.numSlides,
          '/',
          eventData.numSlides
        );
      }

      // Temporarily disable events so that it doesn't dispatch an event on sync
      this.triggerEventsEnabled = false;
      callback.apply(this, args);
      this.triggerEventsEnabled = true;
    }
  },

  /**
   * Event listener for when a navigation item (dot) is clicked
   * @param  {jQuery.Event} event
   */
  onNavigationItemClick(event) {
    event.stopImmediatePropagation();

    if (!this.isAnimating) {
      const index = $(event.currentTarget).index();

      this.resetSlideInterval();
      this.changeSlide(index);
    }
  },

  /**
   * On resize update `autoSlide` (conditions are in `updateAutoSlide` method)
   */
  onResizeUpdateAutoSlide() {
    this.updateAutoSlide();
    this.resetSlideInterval();
  },

  /**
   * Handler for when thumbnail is clicked
   * @param  {jQuery.Event} event
   */
  onThumbnailClick(event) {
    event.preventDefault();

    const index = $(event.currentTarget).index();
    this.changeSlide(index);
  },

  /**
   * Callback for when Transition is loaded
   * @param  {Function} Transition Transition
   */
  onTransitionLoaded(Transition) {
    onWindowLoaded(this.initTransition.bind(this, Transition));
  },

  /**
   * Pause autoslide
   */
  pause(triggerEvent) {
    if (triggerEvent === undefined) {
      triggerEvent = true;
    }

    this.running = false;
    clearInterval(this.autoSlideIntervalId);

    if (triggerEvent) {
      Logger.debug(this.componentName, 'pause');
      this.triggerEvent(EventTypes.CAROUSEL_PAUSED);
    }
  },

  /**
   * Resume auto slide if enabled
   */
  resume(triggerEvent) {
    if (triggerEvent === undefined) {
      triggerEvent = true;
    }

    if (this.autoSlide) {
      this.running = true;
      clearInterval(this.autoSlideIntervalId);

      let index = this.activeIndex;

      if (this.slideIntervalDurations.length < this.transitionManager.numSlides) {
        index = 0;
      }
      this.autoSlideIntervalId = setInterval(
        this.gotoNextSlide.bind(this, false),
        this.slideIntervalDurations[index]
      );
    }

    if (triggerEvent) {
      Logger.debug(this.componentName, 'resume');
      this.triggerEvent(EventTypes.CAROUSEL_RESUMED);
    }
  },

  /**
   * Remove empty slides
   * Check if srcset is present in image, otherwise remove it
   */
  removeEmptySlides() {
    this.transitionManager.removeEmptySlides();
  },

  /**
   * Remove slide from carousel
   * @param  {Int} index           Slide index
   * @param  {Boolean} removeThumbnail Also remove thumbnail
   */
  removeSlide(event, eventData) {
    let index;
    if (eventData) {
      const thumbnails = $('.js-productDetail-thumbnails');
      const imgType = thumbnails.find(`[data-img-type="${eventData}"]`);
      const item = imgType.parents('.js-productDetail-thumbnails-listItem');
      index = item.index();
      item.remove();
    }

    this.transitionManager.initialize();

    // Force load image if the first slide has been removed
    // Others will still be lazy loaded
    if (index === 0) {
      this.loadGridCarouselImage(index);
    }

    this.updateIndexes();
  },

  removeNavigationItem(index) {
    this.$navigationItems.eq(index).remove();
    this.$navigationItems = this.$gridCarousel.find('.js-gridCarousel-navigation-list-item');
  },

  /**
   * Restart auto slide interval if this was running
   */
  resetSlideInterval() {
    const wasRunning = this.running;
    this.pause(false);

    if (this.autoSlide && wasRunning) {
      this.resume(false);
    }
  },

  /**
   * Make the navigation corresponding to the current slide active
   * @param {int} index
   */
  setActiveNavigationItem(index) {
    if (index > this.$navigationItems.length - 1) {
      index -= 2;
    }

    this.$navigationItems.eq(index).addClass('is-active').siblings().removeClass('is-active');
  },

  /**
   * Trigger event with metadata for groups
   * @param  {String} eventType Type of event
   * @param  {Object} eventData Additional data for event
   */
  triggerEvent(eventType, eventData) {
    eventData = eventData || {};

    if (this.triggerEventsEnabled) {
      if (this.group) {
        eventData.group = this.group;
        eventData.initiator = this;
        eventData.numSlides = this.transitionManager.numSlides;
      }

      this.$gridCarousel.trigger(eventType, eventData);
    }
  },

  /**
   * Enable auto slide when:
   * - Autoslide is enabled for this carousel
   * - Device has touch and previous/next buttons are not visible
   */
  updateAutoSlide() {
    if (device.hasTouch && AppSettings.isEnableAutoImageSlide && this.autoSlide) {
      return (this.running = true);
    }

    if (!matchBreakpoint('xsmall') && device.hasTouch) {
      const wasAutoSlide = this.autoSlide;
      this.autoSlide = !this.showPrevNext;

      if (this.autoSlide !== wasAutoSlide) {
        Logger.debug(this.componentName, this.autoSlide ? 'enable' : 'disable', 'autoSlide');

        if (this.autoSlide) {
          this.running = true;
          this.resetSlideInterval();
        }
      }
    }
  },

  /**
   * Update number of slides and activeIndex
   */
  updateIndexes() {
    this.transitionManager.updateIndexes();

    if (this.transitionManager.numSlides === 0) {
      Logger.warn(this.componentName, 'There are no slides in this carousel');
    } else if (this.transitionManager.numSlides === 1) {
      Logger.warn(this.componentName, 'There is only 1 slide in this carousel');
    }
  },
});

export default Factory.create(GridCarousel, {
  onFirstInstanceCreated() {
    SideNavigationResizeHandler.initialize({
      whileAnimating: true,
    });

    if (device.hasTouch) {
      document.jq.on(EventTypes.DIALOG_CLOSE, triggerResizeEvent);
    }

    window.addEventListener('orientationchange', triggerResizeEvent, false);
  },
});
