import SrcSet from 'components/productLister--v2/SrcSet';
import ImageMode from 'components/productLister--v2/ImageMode';

const LIST_TYPES = {
  SEARCH: 'Search Results',
  CATEGORY: 'Category Page',
  RVP: 'Recently Viewed Items',
  WISH_LIST: 'Wishlist',
};

/**
 * Returns analytics data for tile.
 *
 * @param {object} product - Product object.
 * @param {number} position - Tile position number.
 * @param {string} list - List type.
 * @returns {string} Analytics data.
 */
function getAnalyticsData(product, position, list) {
  list = list || (/search/.test(location.pathname) ? LIST_TYPES.SEARCH : LIST_TYPES.CATEGORY);

  return JSON.stringify({
    id: product.materialNumber,
    position,
    name: product.name,
    price: product.basePrice,
    variant: product.mainColorEN,
    brand: product.styleFamilyEN,
    list,
    analyticsType: 'impressions',
    category: product.analyticsCategory,
  });
}

/**
 * Takes care of post processing tile data before rendering.
 *
 * @param {object} params - Params object.
 * @param {object} params.product - Product object.
 * @param {number} params.index - Product index.
 * @param {number} params.currentPage - Page number.
 * @param {number} params.pageSize - Page size.
 * @param {string} [params.imageUrlTemplate] - Image url template string.
 * @param {boolean} [params.isLazy] - Whether or not the images should be lazy loaded.
 * @param {boolean} [params.lqip] - Should add low quality image placeholder (LQIP)
 * @returns {object}
 */
function postProcessProductData({
  product,
  index,
  currentPage,
  pageSize,
  list,
  imageUrlTemplate,
  isLazy,
  lqip,
}) {
  const sfc = product.materialNumber || (product.sku || '').toUpperCase();
  const position = currentPage * pageSize + index + 1;
  const imageMode = ImageMode.getCurrentMode();

  const getSimplifiedImages = product => {
    try {
      return JSON.parse(product.productData).simplifiedImages;
    } catch (e) {
      return product?.productData?.simplifiedImages;
    }
  };

  const simplifiedImages = getSimplifiedImages(product);
  const plpQubitImageViewMode =
    document.querySelector('.js-productLister')?.dataset?.plpQubitImageViewMode;

  const getVariationImageParams = images => {
    const imageTypes = AppSettings.enableDarkBgImages
      ? ['E04', 'F04', 'E02', 'F02', 'E01', 'F01']
      : ['F04', 'E04', 'F02', 'E02', 'F01', 'E01'];

    return images[imageTypes.find(imageType => images[imageType])];
  };

  let urlTemplate;
  let imageParams;
  let imageType;
  let imageVersion;
  let seoDescription;

  imageParams =
    imageMode === ImageMode.MODES.OUTFIT
      ? product.primaryImage
      : product.hoverImage || product.primaryImage;
  if (imageParams) {
    if (plpQubitImageViewMode && imageMode === ImageMode.MODES.PRODUCT && simplifiedImages) {
      imageParams = getVariationImageParams(simplifiedImages) || imageParams;
    }

    urlTemplate = imageUrlTemplate || imageParams.url;
    imageType = imageParams.imageType;
    imageVersion = imageParams.imageVersion;
    seoDescription = imageParams.seoDescription;
    product.image = SrcSet.createSingle(sfc, imageType, imageVersion, seoDescription, urlTemplate);
    product.primaryImages = SrcSet.createSet(
      sfc,
      imageType,
      imageVersion,
      seoDescription,
      urlTemplate
    );

    product.base64BackgroundPrimaryImage = lqip && imageParams.base64BackgroundImage;
    product.isLazy =
      AppSettings.enablePLPLoadMore && isLazy === undefined
        ? true
        : isLazy === undefined
        ? index >= 8
        : isLazy;
  }

  imageParams = imageMode === ImageMode.MODES.OUTFIT ? product.hoverImage : null;
  if (imageParams) {
    if (plpQubitImageViewMode && simplifiedImages) {
      imageParams = getVariationImageParams(simplifiedImages) || imageParams;
    }

    urlTemplate = imageUrlTemplate || imageParams.url;
    imageType = imageParams.imageType;
    imageVersion = imageParams.imageVersion;
    seoDescription = imageParams.seoDescription;
    product.base64BackgroundSecondaryImage = lqip && imageParams?.base64BackgroundImage;
    product.secondaryImages = SrcSet.createSet(
      sfc,
      imageType,
      imageVersion,
      seoDescription,
      urlTemplate
    );
  }

  product.productSignings = (product.signings || []).filter(signing => signing.label.length > 0);

  product.hasDiscountPrice = false;
  product.hasDoubleDiscountPrice = false;
  if (product.formattedFromPrice && product.formattedBasePrice !== product.formattedFromPrice) {
    product.hasDiscountPrice = true;
    if (product.hasDiscountPrice && product.productSignings.length) {
      product.discountSigning = [product.productSignings.shift()];
    }

    if (
      product.formattedIntermediaryPrice &&
      product.formattedFromPrice !== product.formattedIntermediaryPrice
    ) {
      product.hasDoubleDiscountPrice = true;
    }
  }

  if (!product.formattedOriginalPrice) {
    product.formattedOriginalPrice = product.formattedFromPrice || product.formattedBasePrice;
  }

  if (product.productSignings.length) {
    product.hasSignings = true;
  }

  product.analyticsData = getAnalyticsData(product, position, list);

  if (product.productData) {
    product.comingSoon = product.productData.comingSoon;
    product.stockQuantity = product.productData.stockQuantity;
    product.mcc = product.productData.mcc;
  }

  product.productData =
    typeof product.productData === 'object'
      ? JSON.stringify(product.productData)
      : product.productData;

  if (
    product.availableStyleVariantsSorted &&
    typeof product.availableStyleVariantsSorted === 'object'
  ) {
    product.availableStyleVariantsSorted = JSON.stringify({
      variants: product.availableStyleVariantsSorted,
    });
  }

  if (product.fiberRanking) {
    product.productFiberRanking = JSON.stringify(product.fiberRanking);
  }

  return product;
}

export default {
  LIST_TYPES,
  getAnalyticsData,
  postProcessProductData,
};
