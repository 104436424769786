/**
 * Image lazy loading via IntersectionObserver. This lazy loading applies for
 * product tiles of the recommendation carousels and recently viewed products.
 *
 * It replaces TeaserImageLazyLoad and foldDetection modules.
 *
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import showImage from '../productLister/utils/showImage';
import isImageLoaded from './isImageLoaded';

export default class ImageLazyLoad {
  static intersectionObserver;

  static isCarouselItem (target) {
    return Boolean(this.getCarouselTile(target));
  }

  static getCarouselTile(target) {
    return target.closest("a.js-slideCarouselItem")
  }

  static getSiblingImg(target) {
    return target.nextElementSibling;
  }

  static isSecondaryImg(target) {
    return Boolean(Array.of($(target)[0].classList).join(" ").includes("secondary"))
  }

  static testLazyString(str) {
    return str.split("is-lazy")?.[1]?.length === 0
  }

  static isSiblingNotLoaded(target) {
    return Boolean(this.testLazyString(Array.of(this.getSiblingImg(target).classList).join(" ")));
  }

  static isLazyLoaded(entry) {
    return entry.target.classList.contains('is-lazyLoaded');
  }

  static validateSibling(target) {
    if(this.isSiblingNotLoaded(target)) {
      const siblingImg = this.getSiblingImg(target);
      showImage(siblingImg);
      this.swapLazyLoadedClasses(siblingImg);
    }
  }

  static swapLazyLoadedClasses(target) {
    target.classList.remove('is-lazy');
    target.classList.add('is-lazyLoaded');
  }

  static unobserve(classNames) {
    const observables = document.querySelectorAll(classNames);
    if (!observables.length) {
      return;
    }

    observables.forEach(observable => this.intersectionObserver.unobserve(observable));
  }

  /**
   * Add elements to an IntersectionObserver instance
   * @param {string} classNames Always use a container className as context for example
   *                            '.js-slideCarousel--cartRecommendations .is-lazy'"
   */
  static observe(classNames) {
    const observables = document.querySelectorAll(classNames);
    if (!observables.length) {
      return;
    }

    if (!this.intersectionObserver) {
      this.intersectionObserver = new IntersectionObserver(
        entries => {
          const parentClassNames = ['is-loading', 'has-pulse'];

          entries.forEach(entry => {
            const img = entry.target;
            const isLazyLoaded = img.classList.contains('is-lazyLoaded');

            if (entry.intersectionRatio > 0) {
              if (img.style.visibility === 'hidden' && isLazyLoaded) {
                img.style.visibility = 'visible';
                return;
              }
              const parent = img.closest('.js-productTile-images');

              if (!parent) {
                throw new Error('Parent must have className ".js-productTile-images"');
              }

              parent.classList.add('has-pulse');

              showImage(img);
              if (isImageLoaded(img)) {
                img.classList.remove('is-lazy');
                img.classList.add('is-lazyLoaded');
                parent.classList.remove(...parentClassNames);
              } else {
                img.onload = event => {
                  const img = event.target;
                  img.classList.remove('is-lazy');
                  img.classList.add('is-lazyLoaded');
                  parent.classList.remove(...parentClassNames);
                };
              }
            } else {
              if(this.isLazyLoaded(entry) && this.isCarouselItem(entry.target) && !this.isSecondaryImg(entry.target)) {
                this.validateSibling(entry.target)
              }
            }
          });
        },
        {
          root: null,
          rootMargin: '100px 0px 100px 0px',
          threshold: 0,
        }
      );
    }

    observables.forEach(observable => this.intersectionObserver.observe(observable));
  }
}
