/**
 * Triggers native event
 */

  /**
   * Triggers a native event
   * @param {String} eventType Event type for instance `resize` or `pageshow`
   */
  export default function triggerNativeEvent(eventType, props) {
    try {
      let eventName = 'Event';
      let execute = true;
      switch (eventType) {
        case 'pageshow':
        case 'pagehide':
          if ('PageTransitionEvent' in window) {
            eventName = 'PageTransitionEvent';
          } else {
            execute = false;
          }
          break;
      }

      if (execute) {
        if (document.createEvent) {
          let ev;

          try {
            if (eventName !== 'Event') {
              ev = new window[eventName](eventType, props);
            }
          } catch (ex) {}

          if (!ev) {
            ev = document.createEvent('Event');
            ev.initEvent(eventType, true, true);
          }

          window.dispatchEvent(ev);
        } else {
          document.fireEvent(`on${eventType}`);
        }
      }
    } catch (e) {}
  };

