
  export default function isElementVisible(element) {
    const style = window.getComputedStyle(element, null);
    return (
      style.opacity !== '0' &&
      style.display !== 'none' &&
      style.visibility !== 'hidden' &&
      !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length)
    );
  };

