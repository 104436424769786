/**
 * Calculate expanded height of a DOM element and set its when related checkbox is checked.
 *
 *  HTML structure should be like this:
 *
 * <div class="container">
 *   <input type="checkbox" class="checkbox" id="checkbox">
 *   <label for="checkbox">Label</label>
 *   <div class="contentWrapper">
 *     <div class="content"></div>
 *   </div>
 * </div>
 *
 * In above example you would initialize like this:
 *
 * CheckboxAccordion.attachTo('.container', {
 *   checkboxSelector: '.checkbox',
 *   contentSelector: '.content',
 *   wrapperSelector: '.contentWrapper'
 * });
 *
 * @author  Meinaart van Straalen
 */
import $ from 'jquery';
import createLogger from 'components/logger/Logger';
import Factory from 'components/utils/Factory';
import EventTypes from 'components/EventTypes';
import 'jquery.bindTransitionEnd';
import 'jquery.resizeEvents';
import 'jquery.extendPrototype';
import 'components/domutils/Element.jQuery';
import requestIdleCallback from '../utils/requestIdleCallback';

const Logger = createLogger('CheckboxAccordion');
const animationDuration = 500; // ms
const $document = document.jq;
const $window = window.jq;

/*** MODULE ***/
function CheckboxAccordion($element, settings) {
  this.settings = $.extend({}, this.settings, settings);
  this.$container = $element;

  this.eventNamespace += settings.numInstance;

  const idleCallback = () => {
    this.initializeWhenVisible();
  };

  requestIdleCallback(idleCallback);
}

$.extendPrototype(CheckboxAccordion, {
  eventNamespace: '.CheckboxAccordion',
  settings: {
    firstUpdate: true,

    // Parent element which should be checked for visibility,
    // if omited the `this.$container` itself is checked.
    $parent: undefined,
    checkboxSelector: '',
    contentSelector: '',
    selector: '',

    // Listen to this event on document.jq for checking whether the checkbox toggle is visible
    // @type String
    visibleEvent: '',
    wrapperSelector: '',
  },

  /**
   * Listener for change event on checkbox
   *
   * @paramt {object} event      jQuery event object
   * @paramt {object} eventData  Object containing data related to object
   */
  changeHandler(event, eventData) {
    const isExpanded = this.$checkbox.prop('checked');
    if (isExpanded) {
      this.deselectOtherCheckboxes();
    }

    // If method is called by user initiated action eventData is not supplied
    const initiatedByUser = !eventData || eventData.initiatedByUser;
    this.updateToggle(isExpanded, initiatedByUser);
    event.stopPropagation();
  },

  /**
   * Create component, usually called via the initialize method.
   *
   * @param  {object} $container jQuery object
   * @param  {object} settings   Settings that were supplied to attachTo
   */
  initialize() {
    Logger.debug('Initialize', this);
    this.$checkbox = this.$container.children(this.settings.checkboxSelector);
    this.$wrapper = this.$container.children(this.settings.wrapperSelector);

    const expandedHeight = Math.round(
      this.$wrapper.find(this.settings.contentSelector).outerHeight(true)
    );

    // initialHeight = height without any active children
    let initialHeight = expandedHeight;

    // If this element has active children subtract the height of their parents from current height
    const $activeChildren = this.$wrapper.find('.is-active');
    if ($activeChildren.length) {
      const $activeWrapper = $activeChildren.parents(`${this.settings.wrapperSelector}:first`);
      if (!$activeWrapper.is(this.$wrapper)) {
        initialHeight = Math.round(
          initialHeight - $activeWrapper.find(this.settings.contentSelector).outerHeight(true)
        );
      }
    }

    const isChecked = this.$checkbox.prop('checked');

    if (isChecked) {
      changeHeight(this.$wrapper, expandedHeight, true);
    }

    this.$wrapper
      .data('expandedHeight', expandedHeight)
      .data('initialHeight', initialHeight)
      .addClass('is-initialized');

    // Listen to change event
    this.$checkbox.on('change', this.changeHandler.bind(this));

    // Update initial state
    if (isChecked) {
      this.$checkbox.trigger('change', { initiatedByUser: false });
    }
  },

  /**
   * Initialize element when it's visible
   */
  initializeWhenVisible() {
    const isVisible = (this.settings.$parent || this.$container).css('visibility') === 'visible';

    $document.off(this.eventNamespace);
    $window.off(this.eventNamespace);

    if (isVisible) {
      this.initialize();
    } else {
      $window.one(`resizeWidthEnd${this.eventNamespace}`, this.initializeWhenVisible.bind(this));

      if (this.settings.visibleEvent) {
        $document.one(
          this.settings.visibleEvent + this.eventNamespace,
          this.initializeWhenVisible.bind(this)
        );
      }
    }
  },

  /**
   * Unselect selected checkboxes withing sibling containers.
   */
  deselectOtherCheckboxes() {
    Logger.debug('deselectOtherCheckboxes');
    this.$container
      .siblings(this.settings.selector)
      .children(this.settings.checkboxSelector)
      .filter(':checked')
      .prop('checked', false)
      .trigger('change', {
        initiatedByUser: false,
      });
  },

  /**
   * Calculate correct height of parents of supplied object and apply that as CSS.
   */
  updateParents() {
    this.$wrapper.parents(this.settings.wrapperSelector).each(
      function (index, element) {
        const $parent = $(element);
        const expandedHeight = Math.round(
          $parent.find(this.settings.contentSelector).outerHeight(true)
        );
        $parent.data('expandedHeight', expandedHeight);
        changeHeight($parent, expandedHeight, false);
      }.bind(this)
    );
  },

  /**
   * Update height of element based on whether checkbox is checked
   *
   * @param {boolean} isExpanded          Is item expanded
   * @param {boolean} initiatedByUser     If true the height of the parents are updated to correct height.
   */
  updateToggle(isExpanded, initiatedByUser) {
    const targetHeight = isExpanded ? parseInt(this.$wrapper.data('expandedHeight'), 10) : 0;

    changeHeight(this.$wrapper, targetHeight || '', this.settings.firstUpdate);
    this.$wrapper.trigger(EventTypes.ACCORDION_TOGGLE, {
      isExpanded,
      contentContainer: this.$container,
      initiatedByUser,
    });

    // Find parents of same type (that means it's nested)
    if (initiatedByUser || this.settings.firstUpdate) {
      const $parents = this.$wrapper.parents(this.settings.wrapperSelector);
      if ($parents.length) {
        // Calculate new target height of parents to animate it correctly
        $parents.each(function (index, element) {
          const $this = $(element);
          changeHeight(
            $this,
            Math.round(parseInt($this.data('initialHeight'), 10) + targetHeight),
            this.settings.firstUpdate
          );
        });

        // After transition update data values (so we are sure they are correct)
        this.$wrapper
          .unbindTransitionEnd()
          .bindTransitionEnd(this.updateParents.bind(this), animationDuration);
      }
    }

    this.settings.firstUpdate = false;
  },
});

/**
 * Change height of element. Browsers that support transitions just use direct CSS manipulation.
 *
 * @param  {object} $element jQuery object
 * @param  {number} height   New height
 */
function changeHeight($element, height, firstUpdate) {
  $element.css({
    height,
    transitionDuration: firstUpdate ? '0s' : '',
  });
}

export default Factory.create(CheckboxAccordion, {
  addSelectorToSettings: true,
  logger: Logger,
});
