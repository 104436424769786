/**
 * Triggers native resize UI event, we cannot use window.jq.trigger('resize');
 * because for example swipe will not respond to that.
 */
import triggerNativeEvent from './triggerNativeEvent';

let timeoutId;

/**
 * Triggers a native resize event and a jQuery resizeEnd event.
 */
const triggerEvent = function () {
  triggerNativeEvent('resize');

  window.jq
    .trigger('resizeEnd')
    .trigger('resizeUpdate')
    .trigger('resizeWidthUpdate')
    .trigger('resizeWidthEnd');
};

export default function triggerResizeEvent() {
  cancelAnimationFrame(timeoutId);
  timeoutId = requestAnimationFrame(triggerEvent);
}
