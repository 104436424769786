/* global AppSettings */
/**
 * This modules positions an element in its parent.
 * Will mostly be used to contain an image in a picture element,
 * making it work like background: cover;.
 *
 * Does not listen to resize triggers.
 */
import createLogger from 'components/logger/Logger';
import $ from 'jquery';
import imagesLoaded from 'imagesloaded';
import EventTypes from 'components/EventTypes';
import SideNavigationResizeHandler from 'components/sidenavigation/ResizeHandler';
import matchBreakpoint from 'components/utils/matchBreakpoint';
import 'jquery.resizeEvents';

const Logger = createLogger('scaleElementInContainer');

SideNavigationResizeHandler.initialize();

const defaultType = 'cover';

/**
 * Process a container (update positioning)
 *
 * @param  {object}   $container  jQuery object containing wrapper arround $image usually <picture> element
 * @param  {object}   $image      jQuery object that contains the image (usually <img> element)
 * @param  {string}   type        Scale and position type, either "cover" or "contain"
 * @param  {boolean}  onlyWidth   If true element will be 100% width and centered vertically, basically overrided type
 * @param  {boolean}  processLazyLoad Wait for image to be lazy loaded.
 */
const process = function ($container, $image, type, onlyWidth, processLazyLoad) {
  if (processLazyLoad && $container.hasClass('js-lazyLoadPicture')) {
    $container.one(
      EventTypes.LAZY_LOADED,
      process.bind(this, $container, $image, type, onlyWidth, false)
    );
  } else {
    // Only process images that do not have hotspots
    // otherwise hotspot module breaks.
    //
    // Image is converted to background image in the processElementInContainer
    // as a fallback.
    if ($image.is('img:not(.js-has-hotspots-image)')) {
      const image = $image.get(0);
      const targetImageUrl = image.currentSrc || image.src;
      const currentImageUrl = $container
        .css('background-image')
        .replace(/url\("?([^"|)]*)"?\)/, '$1');

      if (currentImageUrl !== targetImageUrl || !targetImageUrl) {
        const callback = processImageInPicture.bind(
          this,
          $container,
          $image,
          type,
          onlyWidth,
          true,
          processLazyLoad
        );

        // quick fix for the fact that imagesLoaded seems to fire
        // before images / pictures are truly loaded.
        // Might be picture element support related
        $image.off('load').on('load', callback);
        imagesLoaded($image, callback);
      } else {
        $container.addClass('is-loaded');
        $image.trigger(EventTypes.SCALE_ELEMENT_IN_CONTAINER_COMPLETE);
      }
    } else {
      imagesLoaded(
        $image,
        processElementInContainer.bind(this, $container, $image, type, onlyWidth)
      );
    }
  }
};

/**
 * Set background image and background-size properties.
 *
 * Called when $image contains a <img> object.
 *
 * @param  {object}   $container  jQuery object containing wrapper arround $image usually <picture> element
 * @param  {object}   $image      Element that contains the image (<img> element)
 * @param  {string}   type        Scale and position type, either "cover" or "contain"
 * @param  {boolean}  onlyWidth   If true image should be 100% width and vertically aligned (overrides type)
 * @param  {boolean}  triggerEvent Trigger event after done, default is true
 */
const processImageInPicture = function ($container, $image, type, onlyWidth, triggerEvent) {
  const image = $image.get(0);
  const imageUrl = image.currentSrc || image.src;

  if (imageUrl && $image.data('scaledElementInContainer') !== imageUrl) {
    if (!$image.data('scaledElementInContainer')) {
      $image.css('visibility', 'hidden');
      $container.addClass('is-loaded');
    }

    if (onlyWidth) {
      $container.css('background-size', '100% auto');
    } else {
      $container.css('background-size', type);
    }

    $image.data('scaledElementInContainer', imageUrl);
    $container.css('background-image', `url(${imageUrl})`).css('opacity', '').addClass('is-loaded');

    if (triggerEvent !== false) {
      $image.trigger(EventTypes.SCALE_ELEMENT_IN_CONTAINER_COMPLETE);
    }
  } else {
    return false;
  }

  return true;
};

/**
 * Scales and positions element in container.
 *
 * @param  {object}   $container  jQuery object containing wrapper arround $image usually <picture> element
 * @param  {object}   $element    jQuery object containing element that should be resized (usually not an <img>)
 * @param  {string}   type        Scale and position type, either "cover" or "contain"
 * @param  {boolean}  onlyWidth   If true image should be 100% width and vertically aligned
 */
const processElementInContainer = function ($container, $element, type, onlyWidth) {
  let windowSize = `${window.innerWidth}x${window.innerHeight}`;

  if ($container.hasClass('js-has-hotspots')) {
    if (
      matchBreakpoint(AppSettings.sideNavigationBreakpointNextToContent) &&
      document.documentElement.classList.contains('has-open-sideNavigation')
    ) {
      const sideNavWidth = $('.js-sideNav-scrollContainer').width();
      windowSize = `${window.innerWidth - sideNavWidth}x${window.innerHeight}`;
    }
  }

  if (
    $container.data('processElementInContainer') !== windowSize ||
    $element.is('.js-videoPlayerFrame')
  ) {
    $container.data('processElementInContainer', windowSize);

    $element.css({
      height: 'auto',
      width: 'auto',
      marginLeft: '',
      marginTop: '',
    });

    const containerHeight = Math.round($container.height());
    const containerWidth = Math.round($container.width());

    const imageHeight = Math.round($element.height()) || containerHeight;
    const imageWidth = Math.round($element.width()) || containerWidth;

    const requestedAspectRatio = containerWidth / containerHeight;
    const actualAspectRatio = imageWidth / imageHeight;
    let newHeight = containerHeight;
    let newWidth = containerWidth;
    let offsetTop = 0;
    let offsetLeft = 0;
    let cssSize = {};

    if (onlyWidth) {
      if (actualAspectRatio <= requestedAspectRatio) {
        newWidth = Math.round(newHeight * actualAspectRatio);
        offsetLeft = Math.round((containerWidth - newWidth) / 2);
      }
    } else if (
      (actualAspectRatio <= requestedAspectRatio && type === 'cover') ||
      (actualAspectRatio > requestedAspectRatio && type === 'contain')
    ) {
      newHeight = Math.round(containerWidth / actualAspectRatio);
      offsetTop = Math.round((containerHeight - newHeight) / 2);
    } else {
      newWidth = Math.round(containerHeight * actualAspectRatio);
      offsetLeft = Math.round((containerWidth - newWidth) / 2);
    }

    cssSize = {
      height: newHeight,
      width: newWidth,
      marginLeft: offsetLeft,
      marginTop: offsetTop,
    };

    if (!matchBreakpoint('xsmall') && !$element.is('.js-videoPlayerFrame')) {
      cssSize.marginLeft = 0;
      cssSize.marginTop = 0;
      cssSize.width = '100%';
      cssSize.height = $element.is('iframe') ? '100%' : 'auto';

      $container.css({
        width: '100%',
        height: '100%',
      });
    }

    // Convert image to background image so it renders better when resized.
    // Above code is only done if for some reason the image element also needs to be sized.
    //
    // Otherwise only method below is used.
    //
    // Know example of this is when image contains hotspots.
    if ($element.is('img')) {
      processImageInPicture($container, $element, type, onlyWidth, false);
    } else {
      $container.addClass('is-loaded');
    }

    $element.css(cssSize);
    $element.trigger(EventTypes.SCALE_ELEMENT_IN_CONTAINER_COMPLETE);
  }
};

/**
 * Positions an element in its parent.
 * Will mostly be used to contain an image in a <picture> element,
 * making it work like `background: cover;`.
 * @param  {object} $picture      Picture jQuery object
 * @param  {string} imageSelector Selector of the child that needs to be contained
 * @param  {string} type          'cover' (default): Works like background: cover;
 *                                'contain': Will always show the full image
 * @param  {bool}   onlyWidth     If true, will only make sure the width is 100%,
 *                                and aligned vertically.
 */
export default function scaleElementInContainer($picture, imageSelector, type, onlyWidth) {
  if (type !== 'contain') {
    type = defaultType;
  }

  $picture.each(function (index, element) {
    const $container = $(element);
    $container.find(imageSelector).each(function (index, element) {
      process($container, $(element), type, onlyWidth, true);
    });
  });
}
