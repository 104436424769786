/* global AppSettings */
/**
 * Triggers resize events when sideNavigation is this.animating. *
 * If selector is provided it will only do so when these elements are present.
 *
 * This is especially useful for pages where GridCarousel (with Swipe.js) are used.
 * These modules only respond to resize events. Also useful for other modules
 * that respond to resize events.
 *
 * @author  Meinaart van Straalen
 */
import $ from 'jquery';
import createLogger from 'components/logger/Logger';
import EventTypes from 'components/EventTypes';
import matchBreakpoint from 'components/utils/matchBreakpoint';
import triggerResizeEvent from 'components/utils/triggerResizeEvent';
import 'components/domutils/Element.jQuery';

const Logger = createLogger('ResizeHandler');

const $document = document.jq;
let $elements;

function SideNavigationResizeHandler() {
  Logger.measure(this, ['onAnimationUpdate']);
}

SideNavigationResizeHandler.prototype = {
  animating: false,
  eventNamespace: '.SideNavigationAnimationHandler',
  eventsBound: false,
  elementSelector: '',

  /**
   * Bind listeners to sideNavigation events.
   */
  bindEvents() {
    if (!this.eventsBound) {
      $document
        .on(
          EventTypes.SIDENAV_ANIMATION_START + this.eventNamespace,
          this.onAnimationStart.bind(this)
        )
        .on(
          EventTypes.SIDENAV_ANIMATION_COMPLETE + this.eventNamespace,
          this.onAnimationComplete.bind(this)
        );

      this.eventsBound = true;
    }
  },

  /**
   * Check if module is enabled. Based on breakpoint.
   *
   * If selector is supplied it also checks whether these elements are present.
   */
  isEnabled() {
    if (this.elementSelector && (!$elements || $elements.length === 0)) {
      $elements = $(this.elementSelector);
    }

    return (
      matchBreakpoint(AppSettings.sideNavigationBreakpointNextToContent) &&
      (!this.elementSelector || $elements.length !== 0)
    );
  },

  /**
   * Checks if this module should be enabled, if so bind events when they are
   * not already bound.
   *
   * Called on module initialization and on resizeEnd event.
   */
  initialize() {
    if (this.isEnabled()) {
      if (!this.eventsBound) {
        this.bindEvents();
      }
    } else if (this.eventsBound) {
      this.unbindEvents();
    }
  },

  /**
   * Event handler, called when sidenavigation stops this.animating
   */
  onAnimationComplete() {
    this.animating = false;

    if (!this.whileAnimating) {
      requestAnimationFrame(this.onAnimationUpdate.bind(this));
    }
  },

  /**
   * Event handler, called when sidenavigation starts this.animating
   */
  onAnimationStart() {
    this.animating = true;

    if (this.whileAnimating) {
      requestAnimationFrame(this.onAnimationUpdate.bind(this));
    }
  },

  /**
   * Event listener, executed on every requestAnimationFrame.
   * Dispatched a resize event.
   */
  onAnimationUpdate() {
    triggerResizeEvent();

    if (this.animating) {
      // Schedule update for next frame
      requestAnimationFrame(this.onAnimationUpdate.bind(this));
    } else {
      // Schedule one last update for next frame
      requestAnimationFrame(triggerResizeEvent);
    }
  },

  /**
   * Remove listeners to sideNavigation events
   */
  unbindEvents() {
    $document.off(this.eventNamespace);
    this.eventsBound = false;
  },
};

const sideNavigationResizeHandler = new SideNavigationResizeHandler();
let initialized = false;

export default {
  /**
   * Initialize module that dispatches resize events when sidenavigation is this.animating.
   * @param  {string} selector Optional selector, if supplied module checks if these elements are present before dispatching events.
   */
  initialize(settings) {
    if (settings && settings.selector) {
      sideNavigationResizeHandler.elementSelector = sideNavigationResizeHandler.elementSelector
        ? `${sideNavigationResizeHandler.elementSelector},${settings.selector}`
        : settings.selector;
    }

    if (settings && settings.whileAnimating) {
      sideNavigationResizeHandler.whileAnimating = true;
    }

    if (!initialized) {
      window.jq.on(
        'resizeStart',
        sideNavigationResizeHandler.initialize.bind(sideNavigationResizeHandler)
      );
      sideNavigationResizeHandler.initialize();
      initialized = true;
    }
  },
};
