/**
 * A Factory for slideCarousels. Distinguishes between a regular slidecarousel
 * and one with ListerTeaser responsive pictures.
 */
import Factory from '../utils/Factory';
import SlideCarousel from './SlideCarousel';
import TeaserSlideCarousel from './TeaserSlideCarousel';

export default Factory.create(SlideCarousel.Component, {
  selectorComponents: {
    '.js-slideCarousel--listerTeasers': TeaserSlideCarousel.Component,
  },
});
