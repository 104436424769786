/**
 * This is a base file for contentPages, like the home page
 */
import $ from 'jquery';
import createLogger from '../components/logger/Logger';
import EventTypes from '../components/EventTypes';
import VideoPlayer from '../components/videoPlayer/videoPlayer';
import VideoControls from '../components/videoPlayer/VideoControls';
import DialogVideoTrigger from '../components/dialogs/dialogVideoTrigger';
import LazyLoadImage from '../components/lazyLoading/LazyLoadImage';
import Hotspots from '../components/hotspots';
import ContentBlockList from '../components/contentBlocks/ContentBlockList';
import GridCarousel from '../components/carousels/GridCarousel';
import device from '../components/utils/device';
import requestIdleCallback from '../components/utils/requestIdleCallback';
import baseInitialize from './base';
import matchBreakpoint from '../components/utils/matchBreakpoint';
import '../components/recentlyviewedproducts/RecentlyViewedProducts';
import '../components/domutils/Element.jQuery';

const Logger = createLogger('contentPages');
Logger.time('initialize', this);

baseInitialize();

if (document.querySelector('.js-lazyLoadPicture')) {
  LazyLoadImage.attachTo('.js-lazyLoadPicture');
}

if (document.querySelector('.js-lazyLoadImage')) {
  LazyLoadImage.attachTo('.js-lazyLoadImage', {
    loadWhenVisible: true,
    viewPortRangeThreshold: 200,
  });
}

if (document.querySelector('.js-pictureSequence')) {
  import(
    /* webpackChunkName: "component--contentBlocks-pictureSequence" */ 'components/contentBlocks/pictureSequence'
  ).then(module => {
    const pictureSequence = module.default;
    pictureSequence.attachTo('.js-pictureSequence');
  });
}

if (document.querySelector('.js-hotspots')) {
  Hotspots.attachTo('.js-hotspots');
}

if (document.querySelector('.js-contentBlock-list')) {
  ContentBlockList.initialize();
}

if (document.querySelector('.js-categoryNavigation')) {
  import(
    /* webpackChunkName: "component--CategoryNavigation" */
    'components/CategoryNavigation'
  ).then(module => {
    const CategoryNavigation = module.default;
    CategoryNavigation.attachTo('.js-categoryNavigation');
  });
}

if (document.querySelector('.contentBlock--clickable')) {
  import(
    /* webpackChunkName: "components-utils--Clickable" */
    'components/utils/Clickable'
  ).then(module => {
    const Clickable = module.default;
    document
      .querySelectorAll('.contentBlock--clickable')
      .forEach(element => new Clickable(element));
  });
}

if (document.querySelector('.js-gridCarousel')) {
  GridCarousel.attachTo('.js-gridCarousel');
}

if (document.querySelector('.js-print')) {
  import(/* webpackChunkName: "component--print" */ 'components/Print').then(module => {
    const Print = module.default;
    new Print('.js-print');
  });
}

function initializeExplodedView() {
  const explodedView = document.querySelectorAll(
    'body:not([class*="productLayout"]) .js-explodedView'
  );
  if (explodedView.length) {
    import(
      /* webpackChunkName: 'import--components-productDetail-ExplodedView' */ 'components/productDetail/ExplodedView'
    ).then(loadedModule => {
      const ExplodedView = loadedModule.default;
      ExplodedView.attachTo(explodedView);
    });
  }
}

function initializeCanvasSequence() {
  import(
    /* webpackChunkName: 'import--components-CanvasSequence' */ 'components/CanvasSequence'
  ).then(loadedModule => {
    const CanvasSequence = loadedModule.default;

    const sequences = window.canvasSequence;
    let settings;

    for (let i = 0; i < sequences.length; i++) {
      settings = sequences[i];
      const selector = settings.id ? `#${settings.id} .contentBlock` : settings.selector;
      CanvasSequence.attachTo(selector, settings);
    }
  });
}

function initializeCanvas360() {
  import(/* webpackChunkName: 'import--components-Canvas360' */ 'components/Canvas360').then(
    loadedModule => {
      const Canvas360 = loadedModule.default;
      const instances = window.canvas360;
      let settings;

      for (let i = 0; i < instances.length; i++) {
        settings = instances[i];
        Canvas360.attachTo(`#${settings.id} .contentBlock--360`, settings);
      }
    }
  );
}

function initializeParallax() {
  import(/* webpackChunkName: 'import--components-Parallax' */ 'components/Parallax').then(
    loadedModule => {
      const Parallax = loadedModule.default;
      Parallax.attachTo('.contentBlock--parallax', {
        contentSelector: '.js-contentBlock-picture, .js-videoPlayer, .js-canvasSequence',
      });
    }
  );
}

function initializeStickyRow() {
  import(/* webpackChunkName: 'import--components-StickyRow' */ '../components/StickyRow').then(
    loadedModule => {
      const StickyRow = loadedModule.default;
      StickyRow.attachTo('.row--is-sticky');
    }
  );
}

function initializeContextMenu() {
  import(/* webpackChunkName: 'import--components-ContextMenu' */ 'components/ContextMenu').then(
    loadedModule => {
      const ContextMenu = loadedModule.default;
      ContextMenu.attachTo('.js-contextMenu');
    }
  );
}

function initializeScrollHint() {
  import(
    /* webpackChunkName: 'import--components-contentBlocks-ScrollHint' */ 'components/contentBlocks/ScrollHint'
  ).then(loadedModule => {
    const ScrollHint = loadedModule.default;
    ScrollHint.attachTo('.js-scrollHint');
  });
}

function initializeChapterNavigation() {
  if (
    document.documentElement.className.indexOf('has-chapter-navigation') !== -1 &&
    !(device.isMobile && !matchBreakpoint('xsmall', device.screenWidth))
  ) {
    import(
      /* webpackChunkName: 'import--components-ChapterNavigation' */ 'components/ChapterNavigation'
    ).then(loadedModule => {
      const ChapterNavigation = loadedModule.default;
      ChapterNavigation.initialize();
    });
  }
}

function initialize(event) {
  let container;
  if (event) {
    container = event.container;
  }

  Logger.info('initialize contentPages in:', container || 'document');

  const videoPlayers = (container || document).querySelectorAll(
    '.js-grid .js-videoPlayer:not([data-attached])'
  );

  if (videoPlayers.length) {
    const videoControlsElements = (container || document).querySelectorAll(
      '.js-grid .js-videoControls'
    );

    VideoControls.attachTo(videoControlsElements);

    // because the "new" Factory defers initialization as well
    // and causes events to be fired before anyone is listening
    requestIdleCallback(function () {
      VideoPlayer.attachTo(videoPlayers);
    });
  }

  const dialogVideoTriggers = (container || document).querySelectorAll('.js-dialogVideoTrigger');

  if (dialogVideoTriggers.length) {
    DialogVideoTrigger.attachTo(dialogVideoTriggers);
  }

  initializeExplodedView();
}

initialize();

document.jq.on(`${EventTypes.DIALOG_LOADED} ${EventTypes.AJAX_CONTENT_LOADED}`, initialize);

document.querySelector('.contentBlock--parallax') && initializeParallax();
typeof window.canvasSequence !== 'undefined' && initializeCanvasSequence();
typeof window.canvas360 !== 'undefined' && initializeCanvas360();
document.querySelector('.row--is-sticky') && initializeStickyRow();
document.querySelector('.js-contextMenu') && initializeContextMenu();
document.querySelector('.js-scrollHint') && initializeScrollHint();
initializeChapterNavigation();

document.jq.trigger(EventTypes.RECENTLY_VIEWED_PRODUCTS_RENDER_REQUEST, {
  excludeSku:
    document.querySelector('.js-primarySku')?.value ||
    document.querySelector('[itemprop="sku"]')?.content,
});

Logger.timeEnd('initialize', this);
