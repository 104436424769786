/**
 * Offload Mustache rendering to worker.
 *
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import $ from 'jquery';
import createLogger from 'components/logger/Logger';
import Mustache from 'mustache';
import MustacheWorker from './MustacheWorker';
import 'g-star/jquery/uuid';

const Logger = createLogger('MustacheWrapper');

const cache = {};
let counter = 0;

let workerAvailable = 'Worker' in window;

let mustacheWorker;
const intervalDuration = 1e3 / 60;

try {
  if (workerAvailable) {
    mustacheWorker = new MustacheWorker();

    mustacheWorker.onerror = error => Logger.debug(error);

    mustacheWorker.addEventListener('message', function initialize(event) {
      mustacheWorker.removeEventListener('message', initialize);
    });
  }
} catch (e) {
  Logger.error(e);
  workerAvailable = false;
}

/**
 * Attach messageHandler which will populate cache
 */
mustacheWorker &&
  mustacheWorker.addEventListener('message', function messageHandler(event) {
    const { html, counter } = event.data;

    if (html && counter) {
      cache[counter] = html;
    }
  });

export default {
  render(template, data) {
    if (!workerAvailable) {
      return Promise.resolve(Mustache.render(template, data));
    }

    return new Promise((resolve, reject) => {
      counter++;
      mustacheWorker.postMessage({ template, data, counter });

      const intervalId = setInterval(
        counter => {
          if (cache[counter]) {
            clearInterval(intervalId);
            const html = cache[counter];
            delete cache[counter];
            if (html === 'error') {
              return reject();
            }
            resolve(html);
          }
        },
        intervalDuration,
        counter
      );
    }).catch(reason => {
      Logger.error(reason);
      window?.newrelic?.noticeError(reason);
    });
  },
};
