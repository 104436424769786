/**
 * A SlideCarousel module that can be used for responsive Lister teaser pictures
 * like the ones found on the product lister pages.
 */
import Factory from '../utils/Factory';
import createLogger from '../logger/Logger';
import SlideCarousel from './SlideCarousel';
import ImageLazyLoad from '../utils/ImageLazyLoad';

const Logger = createLogger('TeaserSlideCarousel');

class TeaserSlideCarousel extends SlideCarousel.Component {
  eventNamespace = '.TeaserSlideCarousel';

  /**
   * @param {boolean} forceChangeSlide
   */
  render(forceChangeSlide) {
    // call the render method of the base component.
    SlideCarousel.Component.prototype.render.call(this, forceChangeSlide);

    const selector = `.${this.$carousel.get(0).className.split(' ').join('.')} .is-lazy`;
    ImageLazyLoad.observe(selector);
  }
}

export default Factory.create(TeaserSlideCarousel, { logger: Logger });
