/**
 * Create srcset for responsive image
 * https://img1.g-star.com/image/private/c_fill,f_auto,{{dimensions}},q_80/v{{version}}/{{sfc}}-{{type}}.jpg
 *
 * @see     tags/responsive/product/productImage.tag
 * @author  Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import Mustache from 'mustache';
const mappings = [
  ['432', '320w'],
  ['519', '384w'],
  ['692', '512w'],
  ['865', '640w'],
  ['1038', '768w'],
];

export default class SrcSet {
  static createSet(sfc, type, imageVersion, seoDescription, imageUrlTemplate) {
    return mappings
      .map(mapping => {
        const imageUrl = this.createSingle(
          sfc,
          type,
          imageVersion,
          seoDescription,
          imageUrlTemplate,
          mapping[0]
        );

        return `${imageUrl} ${mapping[1]}`;
      })
      .join(',');
  }

  static createSingle(sfc, type, version, seoDescription, imageUrlTemplate, height) {
    const data = {
      sfc,
      type,
      version,
      seoDescription,
      dimensions: `h_${height ? height : mappings[4][0]}`,
    };

    return this.createUrl(imageUrlTemplate, data);
  }

  static createUrl(imageUrlTemplate, data) {
    return Mustache.render(imageUrlTemplate, data);
  }
}
