/**
 * This component can be used as a custom created external controller
 * for a video component. All logic concerning video playback is
 * controlled by the video player through events. With use of event triggers
 * the video player can be influenced.
 *
 * @author Erik Noorland
 **/
import createLogger from 'components/logger/Logger';
import $ from 'jquery';
import Factory from 'components/utils/Factory';
import EventTypes from 'components/EventTypes';
const Logger = createLogger('VideoControls');
/**
 * VideoControls
 * @param {jQuery Element} $element
 * @param {Object} settings
 */
function VideoControls($element, settings) {
  Logger.info('Created');

  this.$element = $element;
  this.settings = $.extend({}, this.settings, settings);

  this.initialize();
}

$.extend(VideoControls.prototype, {
  settings: {
    // toggle visibility based on mouse enter or leave event
    autoHide: false,

    // mute trigger is optional
    hasMuteTrigger: false,
  },

  hasProgressBar: false,

  /**
   * Initialize
   */
  initialize() {
    this.$cb = this.$element.closest('.js-contentBlock');
    this.$cbVideo = this.$cb.find('.js-videoPlayer');
    this.$playPauseTrigger = this.$element.find('.js-playPauseTrigger');
    this.$muteTrigger = this.$element.find('.js-muteTrigger');
    this.$progress = this.$element.find('.videoControls-progress');
    this.$progressBar = this.$progress.find('.videoControls-progress-bar');

    if (this.$cbVideo.data('autohide')) {
      this.settings.autoHide = this.$cbVideo.data('autohide');
    }

    if (this.$muteTrigger.length) {
      this.hasMuteTrigger = true;
    }

    if (this.$progressBar.length) {
      this.hasProgressBar = true;
    }

    this.bindEvents();
  },

  /**
   * Bind events
   */
  bindEvents() {
    this.$playPauseTrigger.on('vclick', this.onClickPlayPauseTrigger.bind(this));

    if (this.settings.autoHide) {
      this.$cb
        .on('mouseenter', this.onMouseEnter.bind(this))
        .on('mouseleave', this.onMouseLeave.bind(this));
    }

    if (this.hasMuteTrigger) {
      this.$muteTrigger.on('vclick', this.onClickMuteTrigger.bind(this));
      this.$cbVideo
        .on(EventTypes.VIDEO_MUTED, this.onVideoMuteToggle.bind(this))
        .on(EventTypes.VIDEO_UNMUTED, this.onVideoMuteToggle.bind(this));
    }

    if (this.hasProgressBar) {
      this.$progress.on('vclick', this.onClickProgressBar.bind(this));
    }

    this.$cbVideo
      .on(EventTypes.VIDEO_TIME_UPDATE, this.onVideoTimeUpdate.bind(this))
      .on(EventTypes.VIDEO_LOADED_DATA, this.onVideoLoadedData.bind(this))
      .on(EventTypes.VIDEO_PLAYING, this.onVideoPlaying.bind(this))
      .on(EventTypes.VIDEO_PLAYBACK_STARTED, this.onVideoPlaybackToggle.bind(this))
      .on(EventTypes.VIDEO_PLAYBACK_PAUSED, this.onVideoPlaybackToggle.bind(this))
      .on(EventTypes.VIDEO_PLAYBACK_STOPPED, this.onVideoPlaybackStopped.bind(this));
  },

  /**
   * Handle mouse enter event
   */
  onMouseEnter() {
    this.toggleVisibility(true);
  },

  /**
   * Handle mouse leave event
   */
  onMouseLeave() {
    const isPaused = this.$playPauseTrigger.hasClass('is-paused');

    if (!isPaused) {
      this.toggleVisibility(false);
    }
  },

  /**
   * Handle video mute toggle events
   * @param {Event} event
   * @param {Object} eventData
   */
  onVideoMuteToggle(event, eventData) {
    this.toggleMute(eventData.muted);
  },

  /**
   * Handle video playback stop event
   */
  onVideoPlaybackStopped() {
    this.togglePlayPause(true);
  },

  /**
   * Handle video playback toggle events
   * @param {Event} event
   * @param {Object} eventData
   */
  onVideoPlaybackToggle(event, eventData) {
    this.togglePlayPause(eventData.paused);
  },

  /**
   * Handle mute button click
   */
  onClickMuteTrigger() {
    let triggerEvent = EventTypes.VIDEO_MUTE_REQUEST;

    if (this.$muteTrigger.hasClass('is-muted')) {
      triggerEvent = EventTypes.VIDEO_UNMUTE_REQUEST;
    }

    this.$element.trigger(triggerEvent);
  },

  /**
   * Handles the click on the progress bar
   * @param {Event} event
   */
  onClickProgressBar(event) {
    const $target = $(event.currentTarget);
    const localX = event.clientX - $target.offset().left;
    const eventData = {
      percentage: (localX * 100) / $target.width(),
    };

    this.$element.trigger(EventTypes.VIDEO_SEEK_REQUEST, eventData);
  },

  /**
   * Handle play pause button click
   */
  onClickPlayPauseTrigger() {
    let triggerEvent = EventTypes.VIDEO_PAUSE;

    if (this.$playPauseTrigger.hasClass('is-paused')) {
      triggerEvent = EventTypes.VIDEO_PLAY;
    }

    this.$element.trigger(triggerEvent);
  },

  /**
   * Update current time
   * @param {Event} event
   * @param {Object} eventData
   */
  onVideoTimeUpdate(event, eventData) {
    this.updateTime(eventData.currentTime);

    if (this.hasProgressBar) {
      this.updateProgressBar(eventData.duration, eventData.currentTime);
    }
  },

  /**
   * On data loaded
   * @param {Event} event
   * @param {Object} eventData
   */
  onVideoLoadedData(event, eventData) {
    if (!this.$duration) {
      this.$duration = this.$element.find('.js-time--duration');
    }

    this.$duration.text(secondsToTime(eventData.duration));
    this.togglePlayPause(eventData.paused);
    this.toggleMute(eventData.muted);

    if (!eventData.paused || (eventData.playInBackground && eventData.paused)) {
      this.toggleVisibility(true);
    }
  },

  /**
   * On playing
   * @param {Event} event
   * @param {Object} eventData
   */
  onVideoPlaying(event, eventData) {
    this.togglePlayPause(eventData.paused);
    this.toggleVisibility(true);
  },

  /**
   * Toggle visibility of mute and unmute button
   * @param {boolean} isMuted
   */
  toggleMute(isMuted) {
    if (this.hasMuteTrigger) {
      this.$muteTrigger.toggleClass('is-muted', isMuted);
    }
  },

  /**
   * Toggle visibility of play and pause button
   * @param {boolean} isPaused
   */
  togglePlayPause(isPaused) {
    this.$playPauseTrigger.toggleClass('is-paused', isPaused);
  },

  /**
   * Toggle video controls element visibility with animation
   * @param {Boolean} toggle
   */
  toggleVisibility(toggle) {
    this.$element.toggleClass('is-visible', toggle);
  },

  /**
   * Update the width of the progress bar
   * @param {int} duration
   * @param {int} currentTime
   */
  updateProgressBar(duration, currentTime) {
    const progress = (currentTime * 100) / duration;
    this.$progressBar.css('width', `${progress}%`);
  },

  /**
   * Update the current time output
   * @param {int} currentTime
   */
  updateTime(currentTime) {
    if (!this.$currentTime) {
      this.$currentTime = this.$element.find('.js-time--currentTime');
    }

    this.$currentTime.text(secondsToTime(currentTime));
  },
});

/**
 * Convert seconds to a time string like 2:02
 * @param {Number} seconds
 * @return {String}
 */
function secondsToTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  seconds = (seconds % 60).toFixed(0);
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${minutes}:${seconds}`;
}

export default Factory.create(VideoControls);
