/**
 * Initialize expanding/collapsing of contentBlock-list elements.
 *
 * @author  Meinaart van Straalen
 */
import CheckboxAccordion from 'components/checkboxAccordion/CheckboxAccordion';
import EventTypes from 'components/EventTypes';
import 'components/domutils/Element.jQuery';

export default {
  initialize() {
    [...document.querySelectorAll('.js-contentBlock-list')].map(element => {
      const $parent = element.jq;
      CheckboxAccordion.attachTo($parent.find('.js-contentBlock-list-item'), {
        $parent,
        checkboxSelector: '.js-contentBlock-list-checkbox',
        contentSelector: '.js-contentBlock-sublist',
        wrapperSelector: '.js-contentBlock-sublistContainer',
        visibleEvent: EventTypes.INITIALIZE_VISIBLE_CHECKBOX_ACCORDION,
        firstUpdate: false,
      });
    });
  },
};
