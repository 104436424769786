/**
 * Loads lazy loaded image
 *
 * @author  Meinaart van Straalen
 * @author  Vincent Bruijn
 */

/**
 * Lazy load image
 * @param  {Element} $element Element containing `<picture>` or `<img>` element
 */
export default function showImage(element) {
  if (!element.dataset.isLazyLoaded) {
    const sourceSet = element.dataset.srcset;
    const sourceSetFallback = element.dataset.srcsetFallback;
    if (sourceSet) {
      element.setAttribute('srcset', sourceSet);

      if (sourceSetFallback) {
        element.addEventListener('error', () => {
          if (element.dataset.errorTriggered) return;
          element.dataset.errorTriggered = true;
          element.setAttribute('srcset', sourceSetFallback);
        });
      }
    }

    element.dataset.isLazyLoaded = true;
    return true;
  }
  return false;
}
