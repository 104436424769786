/**
 * Lazy load <picture> elements that have <source> elements with a data-src property.
 *
 * It also processes <img> elements with data-src attributes if found.
 *
 * @author  Meinaart van Straalen
 */
import $ from 'jquery';
import imagesLoaded from 'imagesloaded';
import createLogger from 'components/logger/Logger';
import Factory from 'components/utils/Factory';
import EventTypes from 'components/EventTypes';
import isImageLoaded from 'components/utils/isImageLoaded';
import defineLazyProperty from 'components/utils/defineLazyProperty';
import onWindowLoaded from 'components/utils/onWindowLoaded';
import 'jquery.stickyTrigger';
import 'jquery.extendPrototype';
import 'jquery.resizeEvents';
import 'jquery.scrollEvents';
import 'components/domutils/Element.jQuery';

const Logger = createLogger('LazyLoadImage');

/**
 * Initialize lazy load component
 * @param  {Element} element DOM Element (either <picture> or <img>)
 * @param  {Object} settings       Settings object
 */
function LazyLoadImage(element, settings) {
  Logger.measure(this, ['checkElementInView', 'initialize', 'loadImage']);

  this.initialize(element, settings);
}

$.extendPrototype(LazyLoadImage, {
  eventNamespace: '.LazyLoadImage',
  index: 0,
  isPictureElement: true,
  settings: {
    // If set to true images are loaded when they become visible
    // otherwise they are loaded immediately
    // @type Boolean
    loadWhenVisible: false,

    // Offset for when `loadWhenVisible` is true
    // if element is within this amount of pixels from bottom of the screen
    // it will be loaded.
    // @type int
    viewPortRangeThreshold: 100,
  },

  /**
   * Bind events for when load on scroll is enabled
   */
  bindScrollEvents() {
    window.jq.on(this.events, this.elementInView.bind(this));
  },

  /**
   * Check if element is in the viewport of the user (visible).
   * If so lazyload picture.
   */
  checkElementInView() {
    const screenBottom =
      window.pageYOffset + window.innerHeight + this.settings.viewPortRangeThreshold;
    if (this.offsetTop < screenBottom) {
      this.unbindEvents();
      requestAnimationFrame(this.loadImage.bind(this));
    } else {
      this.checking = false;
    }
  },

  /**
   * Destroy this object (unbind events etc.)
   */
  destroy() {
    this.unbindEvents();

    if (this.checking) {
      cancelAnimationFrame(this.updateId);
    }
  },

  /**
   * Schedule a check to see if element is in the viewport of the user (visible).
   * If so lazyload picture.
   */
  elementInView() {
    if (!this.checking) {
      this.updateId = requestAnimationFrame(this.checkElementInView.bind(this));
    }

    this.checking = true;
  },

  /**
   * Initialize module
   */
  initialize(element, settings) {
    Logger.debug('initialize');
    this.settings = $.extend(this.settings, settings || {});

    this.index = this.settings.numInstance;
    this.element = element;

    this.isPictureElement = this.element.tagName === 'PICTURE';

    if (this.isPictureElement) {
      this.image = this.element.getElementsByTagName('img').item(0);
    } else {
      this.image = this.element;
    }

    defineLazyProperty(this, 'offsetParent', function () {
      return this.element.closest('.js-row');
    });

    Object.defineProperty(this, 'offsetTop', {
      get() {
        return this.offsetParent.offsetTop || this.offsetParent.jq.offset().top;
      },
    });

    this.eventNamespace += this.index;

    if (this.settings.loadWhenVisible) {
      onWindowLoaded(
        function () {
          this.initializeLoadWhenVisible();
        },
        this,
        1000
      );
    } else {
      requestAnimationFrame(() => {
        this.loadImage();
      });
    }
  },

  /**
   * Initialize when this.settings.loadWhenVisible is true
   */
  initializeLoadWhenVisible() {
    this.events = [`scrollUpdate${this.eventNamespace}`, `resizeUpdate${this.eventNamespace}`].join(
      ' '
    );

    this.bindScrollEvents();
    this.elementInView();
  },

  /**
   * Load the image.
   */
  loadImage() {
    // Fade in when image is loaded
    this.toggleLoadingState(true);

    this.loadListener = this.toggleLoadingState.bind(this, false, true);
    this.image.addEventListener('load', this.loadListener);
    imagesLoaded(this.image, this.loadListener);

    let elements;
    const selector = '[data-srcset],[data-src]';

    if (this.isPictureElement) {
      elements = this.element.querySelectorAll(selector);
    } else {
      elements = this.element.matches(selector) ? [this.element] : null;
    }

    if (elements && elements.length) {
      elements.forEach(processAttributes.bind(this, ['srcset', 'src']));
    }
  },

  /**
   * Toggle loading state of image (while loading it's opacity is 0)
   * @param {boolean} isLoading Is image loading
   * @param {boolean} triggerEvent Dispatch lazyLoaded event
   */
  toggleLoadingState(isLoading, triggerEvent) {
    if (!isLoading && !isImageLoaded(this.image)) {
      isLoading = true;
      triggerEvent = false;
    }

    this.element.classList.toggle('is-loading', !!isLoading);

    if (triggerEvent && isImageLoaded(this.image)) {
      this.image.jq.stickyTrigger(EventTypes.LAZY_LOADED);
      this.destroy();
    }
  },

  /**
   * Unbind event listeners
   */
  unbindEvents() {
    if (this.settings.loadWhenVisible) {
      window.jq.off(this.eventNamespace);
    }

    if (this.image && this.loadListener) {
      this.image.removeEventListener('load', this.loadListener);
      delete this.loadListener;
    }
  },
});

/**
 * Check if attribute prefixed with data- exists on DOM element,
 * if it does it sets the value of the data attribute to the attribute without
 * data- prefix and removes the data attribute.
 *
 * @param  {Element} element      DOM Element
 * @param  {String} attribute Attribute name (for instance: src or srcset)
 */
function processAttribute(element, attribute) {
  const value = element.getAttribute(`data-${attribute}`);
  if (value) {
    element.setAttribute(attribute, value);
    element.removeAttribute(`data-${attribute}`);
  }
}

/**
 * Calls processAttribute for each supplied attribute name
 *
 * @param  {array}      attributes   Array with attribute names as string
 * @param  {DOMElement} element      DOMElement
 */
function processAttributes(attributes, element) {
  attributes.forEach(function (attr) {
    processAttribute(element, attr);
  });
}

export default Factory.create(LazyLoadImage, {
  instantiateAfterEvent: EventTypes.READY_FOR_SECONDARY_ASSETS,
  useJQuery: false,
});
