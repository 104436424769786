/**
 * Tracking IntersectionObserver
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
import getProductTileType from 'components/utils/productImpressions';

export default class TrackingObserver {
  constructor(containerElement) {
    this.containerElement = containerElement;
    if (!containerElement) {
      return;
    }

    this.options = {
      root: null,
      rootMargin: '0px 0px',
      threshold: 0.85,
    };

    this.productTileType = getProductTileType(
      containerElement.closest('[data-tile-type]')?.dataset?.tileType || 'plp'
    );

    const parentPathElement = containerElement.closest('[data-plp-category-path]');
    this.plpCategoryPath = (parentPathElement && parentPathElement.dataset.plpCategoryPath) || '';

    const parentPathIdsElement = containerElement.closest('[data-plp-category-path-ids]');

    this.plpCategoryPathIds =
      (parentPathIdsElement && parentPathIdsElement.dataset.plpCategoryPathIds) || '';

    this.createObserver();
  }

  observerCallback(entries) {
    const products = [];
    entries.forEach(entry => {
      if (entry.intersectionRatio >= 0.85) {
        const productContainer = entry.target;
        if (productContainer) {
          this.observer.unobserve(productContainer);
          productContainer.classList.add('is-impression-sent');
          products.push(productContainer.dataset.productData);
        }
      }
    });

    if (products.length) {
      document.jq.trigger(AnalyticsEventTypes.PRODUCT_IMPRESSION, {
        products,
        productTileType: this.productTileType,
        plpCategoryPath: this.plpCategoryPath,
        plpCategoryPathIds: this.plpCategoryPathIds,
      });
    }
  }

  createObserver() {
    if (!this.observer) {
      this.observer = new IntersectionObserver(this.observerCallback.bind(this), this.options); // eslint-disable-line
    }

    const nodeList = this.containerElement.querySelectorAll(
      '.js-productTile:not(.is-impression-sent)'
    );

    if (!nodeList.length) {
      return;
    }

    const tiles = Array.from(nodeList);
    tiles.forEach(tile => this.observer.observe(tile));
  }
}
