/* global $f */
import $ from 'jquery';
import EventTypes from 'components/EventTypes';

const videoVimeoHtml =
  '<iframe id="{{id}}" data-src="{{url}}" width="{{width}}" height="{{height}}" frameborder="0" class="js-videoPlayerFrame {{classNames}}" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';

export default {
  videoPlayerTemplate: videoVimeoHtml,
  vimeoBaseUrl: '//player.vimeo.com/video/',
  vimeoApiUrl: '//vimeo.com/api/v2/video/',

  referencePlayer() {
    this.player = $f(this.$player[0]);
  },

  bindReadyEvent() {
    this.player.addEvent(
      'ready',
      function () {
        this.showVideoFrame();
        this.$player.trigger(EventTypes.VIDEO_PLAYER_READY);
      }.bind(this)
    );
  },

  load() {
    this.player.api('loadVideo');
  },

  pause() {
    this.player.api('pause');
  },

  play() {
    this.player.api('play');
  },

  replay() {
    this.player.api('seekTo', 0);
    this.play();
  },

  /**
   * Handle the seek request event
   * @param {Event} event
   * @param {Object} eventData
   */
  seek(event, eventData) {
    // functionality is only implemented in native player API for now
    // this function needs to be here because it will otherwise throw and error
  },

  stop() {
    this.player.api('pause');
    this.player.api('seekTo', 0);
    this.$player.trigger(EventTypes.VIDEO_PLAYBACK_STOPPED);
  },

  /**
   * Handle mute request event
   */
  mute() {
    this.toggleMute(true);
  },

  /**
   * Handle unmute request event
   */
  unmute() {
    this.toggleMute(false);
  },

  /**
   * Toggle mute setting
   * @param {Boolean} toggle
   */
  toggleMute(toggle) {
    if (toggle) {
      this.player.api('setVolume', 0);
    } else {
      this.player.api('setVolume', 1);
    }

    let triggerEvent = EventTypes.VIDEO_MUTED;
    const eventData = {
      muted: toggle,
    };

    if (!toggle) {
      triggerEvent = EventTypes.VIDEO_UNMUTED;
    }

    this.$player.trigger(triggerEvent, eventData);
  },

  setVideoSource() {
    const url = `${this.vimeoBaseUrl + this.playerSettings.videoId}?${$.param({
      api: 1,
      player_id: this.playerSettings.id,
      autoplay: this.playerSettings.autoplay,
      loop: this.playerSettings.loop,
    })}`;

    this.playerSettings.url = url;
  },
};
