/**
 * The basic slideCarousel. Used on the Product detail page v2 as color selector.
 * This module also functions as a prototype for Other slideCarousels.
 * The number of items showing up in the viewport of the slidecarousel
 * is determined by data attributes on the .js-slideCarousel element that correspond to
 * the global breakpoints used in the site. For example:
 *
 * <div class="js-slideCarousel" data-items="2" data-items-xsmall="4" data-items-medium="6"></div>
 */
import $ from 'jquery';
import Factory from 'components/utils/Factory';
import createLogger from 'components/logger/Logger';
import matchBreakpoint from 'components/utils/matchBreakpoint';
import device from 'components/utils/device';
import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
import EventTypes from 'components/EventTypes';
import requestIdleCallback from 'components/utils/requestIdleCallback';
import 'jquery.resizeEvents';
import 'jquery.extendPrototype';
import 'jquery.scrollEvents';
import 'components/domutils/Element.jQuery';
import getProductTileType from 'components/utils/productImpressions';

const Logger = createLogger('SlideCarousel');

class SlideCarousel {
  /** @type {JQuery} */
  $carousel = undefined;

  /** @type {JQuery} */
  $carouselSlides = undefined;

  /** @type {JQuery} */
  $carouselSlidesContainer = undefined;

  /** @type {JQuery} */
  $carouselSlidesContainerInner = undefined;

  activeSlideStep = 0;

  eventNamespace = '.SlideCarousel';

  index = 0;

  scrollThrottle = undefined;

  slideCarouselProductsInViewport = 0;

  slideResizeEnabled = true;

  slideWidth = 0;

  totalSlideSteps = 0;

  productsInMobileViewportRatio = 0.3;

  /** @type {JQuery.PlainObject} */
  data = null;

  /**
   * Initialize
   * @param {JQuery} $element
   * @param {object} settings
   */
  constructor($element, settings) {
    this.eventNamespace += settings.numInstance;
    this.$carousel = $element;

    requestAnimationFrame(() => {
      this.$carouselSlidesContainer = this.$carousel.find('.js-slideCarousel-slidesContainer');
      this.$carouselSlidesContainerInner = this.$carouselSlidesContainer.find(
        '.js-slideCarousel-slidesContainer-inner'
      );
      this.slideResizeEnabled =
        String(this.$carousel.attr('data-slideCarousel-resize')).toLowerCase() !== 'false';

      this.data = this.$carousel.data();

      // Enable native scrolling for touch devices
      if (device.hasTouch) {
        this.$carousel.find('.js-slideCarousel-slidesContainer').css('overflow', 'scroll');
      }

      if (this.$carouselSlidesContainer.length) {
        const { scrollLeft } = this.$carouselSlidesContainer.get(0);
        this.slideCarouselWidth = this.$carousel.width();

        requestIdleCallback(() => {
          // reset the scrolling state of the container
          if (scrollLeft !== 0) {
            this.$carouselSlidesContainer.get(0).scrollLeft = 0;
          }

          const forceChangeSlide = this.$carousel[0]?.className?.includes('colorSelection');

          this.render(forceChangeSlide);
          this.bindEvents();
          this.$carousel.addClass('js-slideCarousel-initialized');
        });
      }
    });
  }

  /**
   * Bind events
   */
  bindEvents() {
    this.$carousel
      .off()
      .on('click', '.js-slideCarousel-controls-link', this.changeSlide.bind(this));

    document.jq.on(EventTypes.SIDENAV_ANIMATION_COMPLETE, this.render.bind(this, true));

    this.$carousel.on(EventTypes.SLIDE_CAROUSEL_RENDER_REQUEST, this.render.bind(this, [true]));

    if (device.hasTouch) {
      this.$carouselSlidesContainer.off().on('scrollUpdate', this.updateActiveSlideStep.bind(this));
      window.jq
        .off(`resizeWidthEnd${this.eventNamespace}`)
        .on(`resizeWidthEnd${this.eventNamespace}`, this.render.bind(this, true));
    }
  }

  /**
   * Returns the total horizontal margin size if present on the slide.
   *
   * @param {Element} slide - Dom node.
   * @returns {number} Total margin in pixels.
   */
  getSlideMargins(slide) {
    if (!slide) {
      return 0;
    }

    const computedStyle = getComputedStyle(slide);
    return parseInt(computedStyle.marginLeft, 10) + parseInt(computedStyle.marginRight, 10);
  }

  /**
   * Set slideCarousel configuration and widths
   * @param  {boolean} forceChangeSlide Force updating the slides
   */
  render(forceChangeSlide) {
    const { itemCountMedium, itemCountXsmall } = this.data;
    let slideCarouselProductsInViewport = 4;

    if (matchBreakpoint('medium') && itemCountMedium) {
      slideCarouselProductsInViewport = itemCountMedium;
    } else if ((matchBreakpoint('xxsmall') || matchBreakpoint('xsmall')) && itemCountXsmall) {
      slideCarouselProductsInViewport = itemCountXsmall + this.productsInMobileViewportRatio;
    }

    this.$carouselSlides = this.$carousel
      .find('.js-slideCarouselItem')
      .filter(
        (index, slide) =>
          slide && (slide.offsetWidth || slide.offsetHeight || slide.getClientRects().length)
      );

    const slideMargins = this.getSlideMargins(this.$carouselSlides[0]);

    if (forceChangeSlide) {
      this.slideCarouselWidth = this.$carousel.width();
    }

    this.slideCarouselProductsInViewport = slideCarouselProductsInViewport;
    this.totalSlideSteps = this.$carouselSlides.length - slideCarouselProductsInViewport;
    this.slideWidth = Math.round(this.slideCarouselWidth / slideCarouselProductsInViewport);

    if (!this.$carousel.hasClass('js-slideCarousel--colorSelection')) {
      if (this.slideResizeEnabled || device.hasTouch) {
        this.$carouselSlides.css('width', `${this.slideWidth - slideMargins}px`);
        this.$carouselSlidesContainerInner.width(this.slideWidth * this.$carouselSlides.length);
      }
    }

    if (this.$carousel.hasClass('show-style-variants')) {
      import(
        /* webpackChunkName: "import--components-productTile-StyleVariants" */ 'components/productTile/StyleVariants'
      ).then(loadedModule => {
        const StyleVariants = loadedModule.default;
        StyleVariants.attachAll($('.js-productTile__style-variants', this.$carouselSlides).get());
      });
    }

    this.setControlStates();

    if (forceChangeSlide) {
      this.changeSlide();
    }

    this.$carousel.get(0).getAttribute('data-tile-type') && this.initializeTrackingObserver();
  }

  /**
   * Handle slide changes
   * @param {JQuery.Event} event Click event when clicking on the controls
   */
  changeSlide(event) {
    let direction;
    let offset;
    let scrollAmount = this.slideWidth;

    // If changeSlide is called by a click
    if (event) {
      event.preventDefault();
      direction = event.currentTarget.classList.contains('js-slideCarousel-controls-link--right')
        ? 'right'
        : 'left';

      // Calculate offset within a product, because touch devices are able to scroll manually
      // This way the next and previous buttons will snap to a product
      if (device.hasTouch) {
        const scrollLeft = this.$carouselSlidesContainer.scrollLeft();
        const productHalf = this.slideWidth / 2;
        offset = scrollLeft - Math.floor(scrollLeft / this.slideWidth) * this.slideWidth;

        if (direction === 'right' && offset > productHalf) {
          scrollAmount = this.slideWidth * 2 - offset;
        } else if (direction === 'right' && offset <= productHalf) {
          scrollAmount = this.slideWidth - offset;
        } else if (direction === 'left' && offset > productHalf) {
          scrollAmount = offset;
        } else if (direction === 'left' && offset <= productHalf) {
          scrollAmount = this.slideWidth + offset;
        }
      }
    }

    if (direction === 'right') {
      if (this.activeSlideStep === this.totalSlideSteps) {
        // Already is the beginning of the slider
        return false;
      }

      this.animateSlide(scrollAmount, 1);
    } else if (direction === 'left') {
      if (this.activeSlideStep === 0) {
        // Already at the end of the slider
        return false;
      }

      this.animateSlide(-scrollAmount, -1);
    } else {
      const styleVariants = Array.from(
        this.$carousel?.[0]?.querySelectorAll('.js-slideCarouselItem')
      );
      const productStyleVariantIndex = styleVariants.findIndex(styleVariant =>
        styleVariant?.classList.contains('active')
      );

      if (productStyleVariantIndex > 2) {
        const styleVariantWidth =
          this.$carouselSlidesContainer[0].scrollWidth / styleVariants.length;
        const initialScrollAmount = (productStyleVariantIndex - 2.5) * styleVariantWidth;
        const activeSlideStep = initialScrollAmount / this.slideWidth;

        return this.animateSlide(initialScrollAmount, activeSlideStep);
      }

      this.$carouselSlidesContainer.animate(
        {
          scrollLeft: `${this.slideWidth * this.activeSlideStep}px`,
        },
        () => {
          this.activeSlideStep = Math.round(
            this.$carouselSlidesContainer.scrollLeft() / this.slideWidth
          );
          this.setControlStates();
        }
      );
    }
  }

  /**
   * Handle slide animation
   * @param {number} scrollAmount The amount that should be scrolled
   * @param {number} changeStepAmount Upper or lower activeStepSlide by amount
   */
  animateSlide(scrollAmount, changeStepAmount) {
    this.activeSlideStep = Math.ceil(this.activeSlideStep + changeStepAmount);
    this.setControlStates();

    this.$carouselSlidesContainer.animate({
      scrollLeft: `+=${scrollAmount}px`,
    });
  }

  /**
   * Update control states based on current slide step
   */
  setControlStates() {
    // Make controls inactive if all products fit in the viewport
    this.$carousel
      .toggleClass(
        'has-inactiveControls',
        this.$carouselSlides.length <= this.slideCarouselProductsInViewport
      )
      .find('.js-slideCarousel-controls-link--left')
      .toggleClass('is-active', this.activeSlideStep > 0)
      .end()
      .find('.js-slideCarousel-controls-link--right')
      .toggleClass('is-active', this.activeSlideStep < this.totalSlideSteps);
  }

  /**
   * Keep track of the current slide step when scrolling through the products
   * @param {JQuery.Event} event Scroll event
   */
  updateActiveSlideStep(event) {
    const scrollLeft = $(event.currentTarget).scrollLeft();

    if (scrollLeft < 10) {
      this.activeSlideStep = 0;
    } else if (
      scrollLeft >=
      this.$carouselSlidesContainerInner.width() - this.$carousel.width() - 10
    ) {
      this.activeSlideStep = this.totalSlideSteps;
    } else {
      this.activeSlideStep = Math.floor(scrollLeft / this.slideWidth);
    }

    if (this.scrollThrottle) {
      clearTimeout(this.scrollThrottle);
    }

    this.scrollThrottle = setTimeout(() => {
      this.setControlStates();
    }, 50);
  }

  initializeTrackingObserver() {
    const carousel = this.$carousel.get(0);
    const productTileType = getProductTileType(carousel?.dataset?.tileType) || '';
    const plpCategoryPath = carousel.dataset.plpCategoryPath || '';
    const plpCategoryPathIds = carousel.dataset.plpCategoryPathIds || '';

    const options = {
      root: null, // the top-level document's viewport
      rootMargin: '0px 0px',
      threshold: 0.85,
    };

    const observer = new IntersectionObserver(entries => {
      const entriesInView = entries.filter(entry => {
        if (entry.intersectionRatio >= 0.85) {
          observer.unobserve(entry.target);
          return true;
        }
        return false;
      });

      if (entriesInView.length > 0) {
        const visibleProducts = entriesInView.map(entry => entry.target);
        const products = visibleProducts
          .map(product => product.dataset.productData)
          .filter(element => !!element);
        if (!products.length) {
          return;
        }

        document.jq.trigger(AnalyticsEventTypes.PRODUCT_IMPRESSION, {
          products,
          productTileType,
          plpCategoryPath,
          plpCategoryPathIds,
        });
      }
    }, options);

    this.$carouselSlides.toArray().forEach(slide => observer.observe(slide));
  }
}

export default Factory.create(SlideCarousel, { logger: Logger });
