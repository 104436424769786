/* globals YKU */
import EventTypes from 'components/EventTypes';
  const videoYoukuHtml = '<div id="{{id}}" class="js-videoPlayerFrame {{classNames}}"></div>';

  export default {
    videoPlayerTemplate: videoYoukuHtml,
    referencePlayer() {
      this.player = new YKU.Player(this.playerSettings.id, {
        styleid: '0',
        client_id: 'f7581f77f3e51fe1',
        vid: this.playerSettings.videoId,
        autoplay: !!this.playerSettings.autoplay,
        loop: !!this.playerSettings.loop,
        show_related: false,
        newPlayer: true,
        events: {
          onPlayError() {},
          onPlayerReady: function () {
            this.playerReady();
          }.bind(this),
        },
      });
    },

    bindReadyEvent() {
      // fallback currently the onPlayerReady events don't work
      if (this.playerSettings.autoplay) {
        this.onMessageHandler = this.onMessage.bind(this);
        window.addEventListener('message', this.onMessageHandler, 'player.youku');
      } else {
        this.readyId = setTimeout(
          function () {
            this.playerReady();
          }.bind(this),
          1000
        );
      }
    },

    onMessage(event) {
      if (/player\.youku\.com/.test(event.origin)) {
        switch (event.data.msg) {
          case 'onPlayerReady':
          case 'onPlayStart':
            this.playerReady();
            break;
        }
      }
    },

    playerReady() {
      clearTimeout(this.readyId);
      if (this.onMessageHandler) {
        window.removeEventListener('message', this.onMessageHandler);
      }
      this.showVideoFrame();
      this.$player.trigger(EventTypes.VIDEO_PLAYER_READY);
    },

    replay() {
      this.player.seekTo(0);
      this.play();
    },
    play() {
      this.player.playVideo();
    },
    pause() {
      this.player.pauseVideo();
    },

    /**
     * Handle the seek request event
     * @param {Event} event
     * @param {Object} eventData
     */
    seek(event, eventData) {
      // functionality is only implemented in native player API for now
      // this function needs to be here because it will otherwise throw and error
    },
    stop() {
      this.player.pauseVideo();
      this.player.seekTo(0);
    },
    load() {
      // This function needs to be here because it will otherwise throw and error
    },
    mute() {
      // This function needs to be here because it will otherwise throw and error
    },
    unmute() {
      // This function needs to be here because it will otherwise throw and error
    },
  };

