/* global AppState */
/**
 * Adds `currentBreakpoint` to `AppState` object and
 * dispatches BREAKPOINT_CHANGED when breakpoint has changed (updated on resizeEnd)
 *
 * @author Erik Noorland
 * @author Meinaart van Straalen
 */
import $ from 'jquery';
import EventTypes from 'components/EventTypes';
import getCssBreakpointConfig from 'components/utils/getCssBreakpointConfig';
import 'jquery.resizeEvents';
import 'components/domutils/Element.jQuery';

const $window = window.jq;
const $document = document.jq;

const CurrentBreakpoint = function () {
  // Lazy lookup, only look it up when it's first accessed
  Object.defineProperty(AppState, 'currentBreakpoint', {
    get: this.getCurrentBreakpoint.bind(this),
    configurable: true,
  });

  this.bindEvents();
};

$.extend(CurrentBreakpoint.prototype, {
  /**
   * Bind handlers to events
   */
  bindEvents() {
    $window
      .one('resize.setCurrentBreakPointIfUnset', this.setCurrentBreakPointIfUnset.bind(this))
      .on('resizeEnd', this.handleResizeEnd.bind(this));
  },

  /**
   * Returns the current breakpoint name
   * @return {String} Breakpoint name
   */
  getBreakpointName() {
    const breakpointsConfig = getCssBreakpointConfig();

    if (breakpointsConfig !== this.lastBreakpointsConfig) {
      this.lastBreakpointsConfig = breakpointsConfig;
      const breakpoints = breakpointsConfig.split('|')[0].split(',');

      for (let i = breakpoints.length - 1; i >= 0; i--) {
        if (breakpoints[i].indexOf('--') === -1) {
          return breakpoints[i];
        }
      }

      return '';
    }

    return this.currentBreakpoint;
  },

  /**
   * Return (cached) current breakpoint and set it if it has not been set yet
   * @return {String} Current breakpoint
   */
  getCurrentBreakpoint() {
    this.setCurrentBreakPointIfUnset(false);
    return this.currentBreakpoint;
  },

  /**
   * Handles the resize end event
   */
  handleResizeEnd() {
    const breakpoint = this.getBreakpointName();

    if (breakpoint !== this.currentBreakpoint) {
      this.setCurrentBreakpoint(breakpoint);

      $document.trigger
        .bind($document, EventTypes.BREAKPOINT_CHANGED, {
          breakpoint,
        })
        .call();
    }
  },

  /**
   * Stores `currentBreakpoint` on this object and on `AppState` (overwrites setter)
   * @param {String} breakpoint Current breakpoint
   */
  setCurrentBreakpoint(breakpoint) {
    this.currentBreakpoint = breakpoint;
    Object.defineProperty(AppState, 'currentBreakpoint', {
      value: breakpoint,
    });
  },

  /**
   * Called when user starts resizing the screen
   * @param {Boolean} viaEvent Executed via event listener?
   */
  setCurrentBreakPointIfUnset(viaEvent) {
    // Store current breakpoint if not set
    if (this.currentBreakpoint === undefined) {
      this.setCurrentBreakpoint(this.getBreakpointName());

      // Remove event listener when not called via event (otherwise it's removed by jQuery due to `.one`)
      if (!viaEvent) {
        $window.off('resize.setCurrentBreakPointIfUnset');
      }
    }
  },
});

export default new CurrentBreakpoint();
