/* globals AppSettings, AppState */
import $ from 'jquery';
import EventTypes from '../EventTypes';
import createLogger from '../logger/Logger';
import StorageKeys from '../utils/storage/StorageKeys';
import Storage from '../utils/storage/Storage';
import device from '../utils/device';
import matchBreakpoint from '../utils/matchBreakpoint';

const Logger = createLogger('RecentlyViewedProductsManager');

const { isMobile } = AppState;

const MAX_STORED_PRODUCTS =
  isMobile && !matchBreakpoint('xsmall', device.screenWidth) ? 5 : Infinity;

// after 3 days, products get removed from your RVP list
const AGE_THRESHOLD = Date.now() - 3 * 24 * 60 * 60 * 1000;

let instance;

/**
 * @classdesc Central storage manager of recently viewed products.
 *
 * Cooperates with:
 * components/productLister/RecentlyViewedProducts.js
 * components/productDetail/ProductDetail--v2.js
 * components/productDetail/quickShopProduct.js
 *
 * @author Rick Borst <rick.w.borst@gmail.com>
 */
class RecentlyViewedProductsManager {
  /**
   * Returns deprecated product data, required for the old detail page.
   *
   * TODO: Remove after total deprecation of old Recently Viewed Products design.
   *
   * Implemented in:
   * components/productDetail/ProductDetail--v2.js
   * components/productDetail/quickShopProduct.js
   * @param {object} product
   * @param {JQuery} $container
   * @deprecated
   */
  appendDeprecatedProductData(product, $container) {
    const $productSigning = $container.find('.productSigning:first');
    const $productSigningLabel = $productSigning.find('.productSigning-label');

    const signings = {
      discountSigning: [],
      signings: [],
      hasSignings: false,
    };

    const signing = $container.get()[0].querySelector('.js-productSigning');
    if (signing) {
      const label = signing.querySelector('.js-productSigning-label');
      const onSale =
        signing.classList.contains('productSigning--sale') ||
        signing.classList.contains('productSigning--v2--sale');
      const styles = getComputedStyle(signing);
      const signingData = {
        backgroundColour: styles.backgroundColor || '',
        cssClasses: onSale ? 'productSigning--sale productSigning--v2--sale' : '',
        textColour: styles.color || '',
        label: label.innerHTML || '',
      };

      if (onSale) {
        signings.discountSigning.push(signingData);
      } else {
        signings.hasSignings = true;
        signings.signings.push(signingData);
      }
    }

    Object.assign(product, {
      price: ($container.find('.js-productPrice-value').html() || '').trim(),
      salePrice: ($container.find('.js-productPrice-discount').html() || '').trim(),
      discountSigning: signings.discountSigning,
      signings: signings.signings,
      hasSignings: signings.hasSignings,
      signingLabel: $productSigningLabel.html(),
      signingBackground: $.trim($container.find('.js-productSigning').css('background-color')),
      signingText: $.trim($productSigningLabel.css('color')),
      signingClass: $productSigning.hasClass('productSigning--sale') ? 'productSigning--sale' : '',
    });
  }

  /**
   * Adds a product to the product store.
   *
   * @param {object} product - Product object.
   */
  addProductToStore(product) {
    Logger.time('addProductToStore');
    const products = this.fetch();
    const productIndex = products.findIndex(stored => stored.sku === product?.sku);
    const productExistsInStore = ~productIndex;

    if (products.length === MAX_STORED_PRODUCTS || productExistsInStore) {
      // Remove the last item or the existing item from the store
      products.splice(productExistsInStore ? productIndex : MAX_STORED_PRODUCTS - 1, 1);
    }

    products.unshift(product);
    products.forEach(entry => entry.added || (entry.added = Date.now()));

    this.store(products);
    Logger.timeEnd('addProductToStore');
  }

  /**
   * Returns the valid currently stored items,
   * optionally excluding products with the provided sku.
   *
   * @param {string} excludeSku - SKU of products to be excluded.
   * @param {boolean} excludeLast
   * @returns {Array<object>} Array of products.
   */
  getProducts(excludeSku, excludeLast) {
    const products = this.fetch();
    const result = [];

    products.forEach(product => {
      if (product.added && product.added < AGE_THRESHOLD) {
        this.removeProductFromStore(product.sku);
        return;
      }

      product.sku === excludeSku || result.push(product);
    });

    return result.slice(0, excludeLast ? MAX_STORED_PRODUCTS - 1 : MAX_STORED_PRODUCTS);
  }

  /**
   * Removes a product from the product store.
   *
   * @param {string} sku - Product sku.
   */
  removeProductFromStore(sku) {
    if (sku) {
      this.store(this.fetch().filter(product => product.sku !== sku));
    }
  }

  /**
   * Removes all products from the product store.
   *
   */
  removeAllProductFromStore() {
    this.store([]);
  }

  /**
   * Stores the products to storage.
   *
   * @param {array} products - Products.
   */
  store(products) {
    Storage.setItem(StorageKeys.RECENTLY_VIEWED_PRODUCTS, products.slice(0, MAX_STORED_PRODUCTS));

    document.dispatchEvent(new CustomEvent(EventTypes.RECENTLY_VIEWED_PRODUCTS_LIST_UPDATED));
  }

  /**
   * Returns the stored products.
   *
   * @returns {array} Stored products.
   */
  fetch() {
    return Storage.getItem(StorageKeys.RECENTLY_VIEWED_PRODUCTS) || [];
  }
}

export default {
  /**
   * @returns {RecentlyViewedProductsManager} Instance of RecentlyViewedProductsManager
   */
  getInstance() {
    return instance || (instance = new RecentlyViewedProductsManager());
  },
};
