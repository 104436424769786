/* globals YT */
import EventTypes from 'components/EventTypes';
  const videoYoutubeHtml = '<div id="{{id}}" class="js-videoPlayerFrame {{classNames}}"></div>';

  export default {
    youtubeBaseUrl: '//www.youtube.com/embed/',
    videoPlayerTemplate: videoYoutubeHtml,

    referencePlayer() {
      const playerVars = {
        enablejsapi: 1,
        controls: this.playerSettings.hasNativeControls ? 1 : 0,
        autohide: 1,
        fs: this.playerSettings.allowfullscreen ? 1 : 0,
        color: 'white',
        autoplay: this.playerSettings.autoplay ? 1 : 0,
        loop: this.playerSettings.loop ? 1 : 0,
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3,
        modestbranding: 1,
      };

      this.player = new YT.Player(this.playerSettings.id, {
        videoId: this.playerSettings.videoId,
        playerVars,
      });

      const iframe = this.player.getIframe();
      iframe.dataset.src = iframe.src;
      iframe.setAttribute('src', '');

      if (this.playerSettings.loop) {
        this.player.addEventListener('onStateChange', this.loopVideo.bind(this));
      }
    },

    bindReadyEvent() {
      this.player.addEventListener(
        'onReady',
        function () {
          this.showVideoFrame();
          this.$player.trigger(EventTypes.VIDEO_PLAYER_READY);
        }.bind(this)
      );
    },

    load() {
      // This function needs to be here because it will otherwise throw and error
    },

    loopVideo(state) {
      if (state.data === 0) {
        this.player.loadVideoById(this.playerSettings.videoId);
      }
    },

    pause() {
      this.player.pauseVideo();
    },

    play() {
      this.player.playVideo();
    },

    replay() {
      this.player.seekTo(0);
      this.play();
    },

    /**
     * Handle the seek request event
     * @param {Event} event
     * @param {Object} eventData
     */
    seek(event, eventData) {
      // functionality is only implemented in native player API for now
      // this function needs to be here because it will otherwise throw and error
    },

    stop() {
      this.player.pauseVideo();
      this.player.seekTo(0);
      this.$player.trigger(EventTypes.VIDEO_PLAYBACK_STOPPED);
    },

    /**
     * Handle mute request event
     */
    mute() {
      this.toggleMute(true);
    },

    /**
     * Handle unmute request event
     */
    unmute() {
      this.toggleMute(false);
    },

    /**
     * Toggle mute setting
     * @param {Boolean} toggle
     */
    toggleMute(toggle) {
      if (toggle) {
        this.player.mute();
      } else {
        this.player.unMute();
      }

      let triggerEvent = EventTypes.VIDEO_MUTED;
      const eventData = {
        muted: toggle,
      };

      if (!toggle) {
        triggerEvent = EventTypes.VIDEO_UNMUTED;
      }

      this.$player.trigger(triggerEvent, eventData);
    },
  };

