
  // Valid animation types, set as `data-animation` attribute on DOM element
  const AnimationTypes = {
    FADE: 'fade',
    NO_TRANSITION: 'noTransition',
    SLIDE: 'slide',
    SLIDE_OFF: 'slideOff',
    SLIDE_OVER: 'slideOver',
    REVEALING_CLOCK: 'canvas_revealingClock',
  };

  export default AnimationTypes;

