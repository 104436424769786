const getProductTileType = datasetValue => {
  const { pageType } = AppSettings;

  if (datasetValue === 'recommendationseinstein') {
    const pageTypes = {
      CATEGORY: 'recommendations_PLP',
      PRODUCT: 'recommendations_PDP',
      HOME: 'recommendations_HP',
      CART: 'recommendations_CART',
      PURCHASE: 'recommendations_OCP',
      DEFAULT: datasetValue,
    };

    return pageTypes[pageType] || pageTypes['DEFAULT'];
  }

  return datasetValue;
};

export default getProductTileType;
