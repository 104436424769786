/* global AppSettings */
import $ from 'jquery';
import Factory from 'components/utils/Factory';
import createLogger from 'components/logger/Logger';
import Dialog from 'components/dialogs/dialog';
import DialogTypes from 'components/dialogs/Types';
import EventTypes from 'components/EventTypes';
import matchBreakpoint from 'components/utils/matchBreakpoint';
import 'jquery.resizeEvents';
import 'jquery.extendPrototype';
import 'components/domutils/Element.jQuery';
import { gsap, CSSPlugin } from 'gsap/all';

const Logger = createLogger('dialogVideoTrigger');
gsap.registerPlugin(CSSPlugin);
const $window = window.jq;
const $document = document.jq;

/*** MODULE ***/
/**
 * Initialize component
 * @return {object} jQuery object containing trigger
 */
function DialogVideoTrigger($element) {
  Logger.measure(this, ['openDialog']);

  this.$element = $element;
  this.$element.on('vclick', this.openDialog.bind(this));
}

$.extendPrototype(DialogVideoTrigger, {
  /**
   * Call back function for when dialog has been created
   * @param  {object} dialog Dialog instance
   */
  handleDialogCreated(dialog) {
    this.dialog = dialog;

    this.$element.trigger(EventTypes.DIALOG_LOADED);
    dialog.$dialogContentWrapper.css({
      opacity: 0,
    });

    dialog.$dialogContent.find('.js-grid').removeClass('grid--topOffset');

    const $videoPlayer = dialog.$dialogContent.find('.js-videoPlayer');

    if ($videoPlayer.length) {
      this.$videoPlayer = $videoPlayer;
      $videoPlayer.on(
        EventTypes.VIDEO_COMPONENT_INITIALIZED,
        this.handleVideoComponentInitialized.bind(this)
      );
      document.body.classList.add('has-videoDialog');
      $window.on('resizeEnd.dialogVideo', this.resizeDialog.bind(this));
      $document.on(EventTypes.DIALOG_CLOSED, this.releaseDialog.bind(this));
    }
  },

  /**
   * Event handler for when video component has been initialized
   */
  handleVideoComponentInitialized() {
    this.resizeDialog();

    gsap.to(this.dialog.$dialogContentWrapper, {
      duration: 0.25,
      css: { opacity: 1 },
    });
  },

  /**
   * Open dialog after event has been triggered
   * @param  {object} event jQuery event object
   */
  openDialog(event) {
    event.preventDefault();

    const $currentTarget = $(event.currentTarget);
    const cssDialogClasses = $currentTarget.attr('data-dialog-modifier');

    Dialog.show({
      className: `dialog--default dialog--video${cssDialogClasses ? ` ${cssDialogClasses}` : ''}`,
      filterResponseSelector: '.grid:first',
      id: 'TriggerDialog',
      type: DialogTypes.VIDEO,
      url: encodeURI($currentTarget.attr('href')),
      vAlignMiddleOnSmallScreen: true,
      vAlignMiddle: true,
    }).done(this.handleDialogCreated.bind(this));
  },

  /**
   * Resize dialog based on videoplayer size
   */
  resizeDialog() {
    if (this.dialog.$dialog === undefined) {
      this.releaseDialog();
    } else {
      const hasLargeScreen = matchBreakpoint('xsmall');
      const videoPlayerAspectRatio =
        this.$videoPlayer.parent().innerWidth() / this.$videoPlayer.parent().innerHeight();
      const windowAspectRatio =
        window.innerWidth / (window.innerHeight - AppSettings.topNavigationHeight);

      let width = '';
      const height =
        window.innerHeight -
        (hasLargeScreen
          ? AppSettings.topNavigationHeight * 1.5
          : AppSettings.topNavigationHeight * 1.75);

      if (videoPlayerAspectRatio < windowAspectRatio) {
        width = height * videoPlayerAspectRatio;
      }

      this.dialog.$dialog.find('.js-dialog-content, .js-closeDialog').css({
        width,
      });

      requestAnimationFrame(() => {
        this.$videoPlayer.trigger(EventTypes.VIDEO_ADJUST_SIZE);
      });

      requestAnimationFrame(() => {
        this.dialog.positionDialogContent();
      });
    }
  },

  releaseDialog() {
    document.body.classList.remove('has-videoDialog');
    $window.off('resizeEnd.dialogVideo');
  },
});

export default Factory.create(DialogVideoTrigger, { logger: Logger });
