
  export default function isImageLoaded(image) {
    if (image instanceof window.jQuery) {
      image = image.get(0);
    }

    if (!image) {
      return false;
    }

    return !!(
      image.complete ||
      (typeof image.naturalWidth !== 'undefined' && image.naturalWidth !== 0)
    );
  };

