// Webkit browsers have an annoying bug that screws with aspect ratio
// of images when device is rotated. This does not occur on iOS because of the rotation bug fix.
import matchBreakpoint from 'components/utils/matchBreakpoint';
import device from 'components/utils/device';
import 'components/domutils/Element.jQuery';

const $window = window.jq;

export default function ($element) {
  if (device.isWebkitBrowser && (device.osName !== device.IOS || device.osVersion < 8)) {
    let prevWindowWidth = device.windowWidth;
    let orientationUpdateId;

    $window.on('orientationchange resizeEnd', function (event) {
      const { windowWidth } = device;
      const hasLargeScreen = matchBreakpoint('xsmall');

      if (
        !hasLargeScreen &&
        (prevWindowWidth !== windowWidth || event.type === 'orientationchange')
      ) {
        cancelAnimationFrame(orientationUpdateId);
        orientationUpdateId = requestAnimationFrame(() => {
          // This fixes a weird bug in webkit, it forces a redraw (do not remove .height())
          $element.css('display', 'none').height();
          $element.css('display', '');
        });

        prevWindowWidth = windowWidth;
      }
    });
  }
}
