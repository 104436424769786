/* global AppSettings */
import $ from 'jquery';
import createLogger from 'components/logger/Logger';
import nativePlayerApi from 'components/videoPlayer/nativePlayerApi';
import vimeoPlayerApi from 'components/videoPlayer/vimeoPlayerApi';
import youkuPlayerApi from 'components/videoPlayer/youkuPlayerApi';
import youtubePlayerApi from 'components/videoPlayer/youtubePlayerApi';
import device from 'components/utils/device';
import scaleElementInContainer from 'components/utils/scaleElementInContainer';
import EventTypes from 'components/EventTypes';
import Mustache from 'mustache';
import matchBreakpoint from 'components/utils/matchBreakpoint';
import 'imagesloaded';
import 'jquery.resizeEvents';
import 'components/domutils/Element.jQuery';
import 'jquery.scrollEvents';

const Logger = createLogger('videoPlayer');

let playerCount = 0;
const defaultPlayerType = 'native';
let hasObjectFitSupport;

try {
  // window.CSS.supports is not supported by IE (of course)
  hasObjectFitSupport = window.CSS.supports('object-fit', 'cover');
} catch (error) {
  hasObjectFitSupport = 'object-fit' in document.body.style;
}

const ControlTypes = {
  NONE: 'none',
  NATIVE: 'native',
  CUSTOM: 'custom',
};

function VideoPlayer($videoContainer, options) {
  Logger.time('initialize', this);
  options = options || {};

  this.playerSettings = {
    allowfullscreen: false,
    autoplay: false,
    classNames: 'contentBlock-video-frame ',
    enabled: true,
    height: '100%',
    id: '',
    loop: true,
    controls: ControlTypes.NONE,
    hasNativeControls: false,
    playerType: 'native',
    playInBackground: false,
    posterUrl: '',
    preload: 'auto',
    requestedAspectRatio: 16 / 9,
    url: '',
    videoSources: [],
    width: '100%',
    isVideoLoading: false,
  };

  playerCount++;

  this.extendSettings = function () {
    const cmsData = $videoContainer.data() || {};

    this.$videoContainer = $videoContainer;
    $.extend(this.playerSettings, options, cmsData);
    this.playerSettings.classNames += ` contentBlock-video-frame--${this.playerSettings.playerType}`;

    if (device.isMobile && !matchBreakpoint('xsmall', device.screenWidth)) {
      this.playerSettings.loop = this.playerSettings.playInBackground;
    }

    this.playerSettings.hasNativeControls = this.playerSettings.controls === ControlTypes.NATIVE;

    // only allow native controls to be displayed when video is not backgroundVideo
    this.playerSettings.hasNativeControls =
      this.playerSettings.hasNativeControls && !this.playerSettings.playInBackground;

    this.enabled = true;

    this.generateVideoPlayerId();
  };

  this.generateVideoPlayerId = function () {
    this.playerSettings.id = `contentVideo-${new Date().valueOf() + playerCount}${
      options.id || ''
    }`;
  };

  this.getPlayerHtml = function () {
    const playerHtml = Mustache.render(this.videoPlayerTemplate, this.playerSettings);
    return $(playerHtml);
  };

  this.renderPlayer = function () {
    const playerHtml = this.getPlayerHtml();
    this.$videoContainer.find('.js-videoContainer').append(playerHtml);
    this.$player = this.$videoContainer.find('.js-videoPlayerFrame');
  };

  this.extendPlayerApi = function () {
    switch (this.playerSettings.playerType) {
      case 'native':
        $.extend(this, nativePlayerApi);
        break;
      case 'vimeo':
        $.extend(this, vimeoPlayerApi);
        break;
      case 'youtube':
        $.extend(this, youtubePlayerApi);
        break;
      case 'youku':
        $.extend(this, youkuPlayerApi);
        break;
      default:
    }
  };

  this.bindReadyEvent = function () {};
  this.setVideoSource = function () {};

  this.isVideoLoaded = function () {
    const video = this.$player.length
      ? this.$player.get(0)
      : this.$videoContainer.find('video').get(0);
    return !!(video && video.currentTime > 0 && !video.paused && video.readyState > 2);
  };

  this.isVideoInViewport = function () {
    const observable = this.$videoContainer.get(0);
    const intersectionObserver = new IntersectionObserver(
      (entries, oberver) => {
        if (!entries.length) {
          return;
        }
        if (entries[0].isIntersecting) {
          if (!this.videoLoaded) {
            this.loadVideo();
          }

          if (this.playerSettings.autoplay && this.videoLoaded) {
            this.play();
          }
        } else {
          this.pause();
        }
      },
      {
        root: null,
        rootMargin: '100px 0px 100px 0px',
        threshold: 0,
      }
    );
    intersectionObserver.observe(observable);
  };

  this.loadVideo = function () {
    let sourceElement;

    switch (this.playerSettings.playerType) {
      case 'youtube':
        sourceElement = $(this.player.getIframe());
        break;
      case 'vimeo':
        sourceElement = $(this.player.element);
        break;
      case 'youku':
        sourceElement = $(`#${this.player.select._target}`).find('iframe');
        break;
      default:
        sourceElement = this.$player.find('source');
    }

    if (sourceElement) {
      const videoSrc = sourceElement.data('src');
      const currentSrc = sourceElement.attr('src');

      if (currentSrc !== videoSrc) {
        sourceElement.attr('src', videoSrc);
        this.playerSettings.isVideoLoading = false;
      }
    }

    if (!this.playerSettings.isVideoLoading && !this.isVideoLoaded()) {
      this.playerSettings.isVideoLoading = true;
      this.load();
    }
  };

  this.bindEvents = function () {
    this.$videoContainer.on(EventTypes.VIDEO_REPLAY_REQUEST, this.replay.bind(this));
    this.$videoContainer.on(EventTypes.VIDEO_PLAY, this.play.bind(this));
    this.$videoContainer.on(EventTypes.VIDEO_PAUSE, this.pause.bind(this));
    this.$videoContainer.on(EventTypes.VIDEO_SEEK_REQUEST, this.seek.bind(this));
    this.$videoContainer.on(EventTypes.VIDEO_STOP_REQUEST, this.stop.bind(this));
    this.$videoContainer.on(EventTypes.VIDEO_MUTE_REQUEST, this.mute.bind(this));
    this.$videoContainer.on(EventTypes.VIDEO_UNMUTE_REQUEST, this.unmute.bind(this));
    this.$player.on(EventTypes.VIDEO_PLAYER_READY, this.checkMuteAttribute.bind(this));

    this.$player.on(`playing, ${EventTypes.VIDEO_PLAYER_READY}`, this.onVideoLoaded.bind(this));

    if (this.isVideoLoaded()) {
      this.onVideoLoaded();
    }

    if (this.playerSettings.playInBackground) {
      this.isVideoInViewport();
    } else {
      this.loadVideo();
    }

    window.jq.on(
      `resizeUpdate resizeEnd ${EventTypes.VIDEO_ADJUST_SIZE}`,
      function () {
        if (this.useScaleElementInContainer()) {
          scaleElementInContainer(
            this.$videoContainer,
            '.js-contentBlock-video-poster, .js-videoPlayerFrame',
            'cover',
            !this.playerSettings.playInBackground
          );
        }
      }.bind(this)
    );

    if (device.osName === device.ANDROID) {
      import(/* webpackChunkName: "fixVideoEvents" */ 'components/utils/fixVideoEvents').then(
        fixVideoEvents => {
          fixVideoEvents(this.$player);
        }
      );
    }
  };

  this.onVideoLoaded = function () {
    this.videoLoaded = true;
    this.$videoContainer.addClass('is-videoLoaded');
  };

  this.referencePlayer = function () {
    this.player = this.$player[0];
  };

  this.showVideoPoster = function () {
    this.$videoContainer.removeClass('is-loadingVideoPoster');
  };

  this.showVideoFrame = function () {
    if (this.useScaleElementInContainer()) {
      scaleElementInContainer(
        this.$videoContainer,
        '.js-contentBlock-video-poster, .js-videoPlayerFrame',
        'cover',
        !this.playerSettings.playInBackground
      );
    }

    this.$videoContainer.removeClass('is-loadingVideo');
  };

  this.initialize = function () {
    const $poster = this.$videoContainer.find('.js-contentBlock-video-poster');

    // Check every 3 frames if document is ready, if it is check if poster image is loaded,
    // this is to give the video component a chance to fade in first.
    getDocumentReadyPromise(3)
      .then($poster.imagesLoaded.bind($poster, this.onPosterImageLoaded.bind(this)))
      .catch(reason => {
        Logger.error(reason);
        window?.newrelic?.noticeError(reason);
      });

    this.$videoContainer.attr('data-attached', true);
  };

  this.checkMuteAttribute = function () {
    if (String(this.$videoContainer.attr('data-mute')).toLowerCase() === 'true') {
      this.$videoContainer.trigger(EventTypes.VIDEO_MUTE_REQUEST);
    }
  };

  this.onPosterImageLoaded = function (imagesLoaded) {
    requestAnimationFrame(() => {
      if (this.useScaleElementInContainer()) {
        scaleElementInContainer(
          this.$videoContainer,
          '.js-contentBlock-video-poster',
          'cover',
          !this.playerSettings.playInBackground
        );
      }
      this.showVideoPoster();
    });

    requestAnimationFrame(() => {
      this.$videoContainer.trigger(EventTypes.VIDEO_COMPONENT_INITIALIZED);
    });
  };

  /**
   * Check whether we should use scaleElementInContainer or not
   * @return {Boolean}
   */
  this.useScaleElementInContainer = function () {
    return !hasObjectFitSupport || this.playerSettings.playerType !== 'native';
  };

  /**
   * Check every specified number of frames if document, if so resolve promise.
   *
   * @param  {Int} frames   Number of requestAnimationFrames to check
   */
  function getDocumentReadyPromise(frames) {
    return new Promise((resolve, reject) => {
      const intervalId = setInterval(() => {
        if (document.readyState === 'complete') {
          clearInterval(intervalId);
          resolve();
        }
      }, (1000 / 60) * (frames || 1));
    });
  }

  // extend external settings
  this.extendSettings();

  if (!this.enabled) {
    this.$videoContainer.addClass('is-videoDisabled').removeClass('is-loading is-loadingVideo');
    this.showVideoPoster();
    return;
  }

  // get the source specific api (native, vimeo, youtube)
  this.extendPlayerApi();

  // get the preferred source url(s) for the video. Adds query params in
  // video + youtube, generates <source element data for native)
  this.setVideoSource();

  // render the player template and store the jquery object
  this.renderPlayer();

  // make the video dom element (<video /> or <iframe /> available for the api)
  this.referencePlayer();

  // bind the ready event
  this.bindReadyEvent();

  // bind the general events
  this.bindEvents();

  // go!
  this.initialize();

  Logger.timeEnd('initialize', this);
}

const ContentVideoFactory = {
  youtubeDeferred: null,
  youkuDeferred: null,

  attachTo(selector, options) {
    Logger.time('attachTo', this);
    const $videoContainers = $(selector);
    options = options || {};

    $videoContainers.each(
      function (i, element) {
        const $container = $(element);
        let playerType = $container.attr('data-player-type') || defaultPlayerType;

        // fallback youku player type for CN via componentAttribute
        if (AppSettings.country === 'CN') {
          const youkuId = $container.parent().attr('data-youku-id');

          if (youkuId) {
            playerType = 'youku';
            $container.attr('data-player-type', playerType);
            $container.attr('data-video-id', youkuId);
          }
        }

        switch (playerType) {
          case 'native':
            new VideoPlayer($container, options);
            break;

          case 'youtube':
            if (!this.youtubeDeferred) {
              this.youtubeDeferred = $.Deferred();

              const tag = document.createElement('script');
              tag.src = 'https://www.youtube.com/iframe_api';
              tag.async = true;
              const firstScriptTag = document.getElementsByTagName('script')[0];
              firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

              window.onYouTubeIframeAPIReady = function () {
                this.youtubeDeferred.resolve();
              }.bind(this);
            }

            this.youtubeDeferred.done(function () {
              new VideoPlayer($container, options);
            });
            break;

          case 'youku':
            if (!this.youkuDeferred) {
              this.youkuDeferred = $.Deferred();
              window.GSRD.loadAsset('//player.youku.com/jsapi').then(() => {
                this.youkuDeferred.resolve();
              });
            }

            this.youkuDeferred.done(function () {
              new VideoPlayer($container, options);
            });
            break;

          case 'vimeo':
            window.GSRD.loadAsset('//f.vimeocdn.com/js/froogaloop2.min').then(() => {
              new VideoPlayer($container, options);
            });
            break;
          default:
        }
      }.bind(this)
    );
    Logger.timeEnd('attachTo', this);
  },
};

export default ContentVideoFactory;
