/* globals AppSettings */
/**
 * ImageMode handles the different views for product tiles.
 * The two variants are:
 * 1. View product on a model, i.e. Modes.OUTFIT
 * 2. View plain product, i.e. Modes.PRODUCT
 *
 * @author Rick Borst <rick.w.borst@gmail.com>
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import EventTypes from 'components/EventTypes';
import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
/**
 * Possible ImageMode modes
 * @const Object.<string,string>
 */
const Modes = {
  OUTFIT: 'outfit',
  PRODUCT: 'product',
};

/**
 * @type Object.<string,string>
 */
const Selectors = {
  TILE_IMAGE: '.js-productTile-image',
  SECONDARY_TILE_IMAGE: '.js-productTile-image--secondary',
  IMAGE_MODE_OPTIONS: '.js-lister-image-mode',
  IMAGE_MODE_OPTION: '.js-lister-image-mode__option',
};

/**
 * @type Object.<string,string>
 */
const Classes = {
  IMMEDIATE: 'is-immediate',
  LOADED: 'is-loaded',
  OPTION_ACTIVE: 'lister-image-mode__option--active',
};

/**
 * Returns a regular expression matching the image
 * mode parameters within a url.
 * @const {RegExp}
 */
const IMAGE_MODE_REGEXP = new RegExp(`[?&]?imgmode=(${Modes.OUTFIT}|${Modes.PRODUCT})`);

/**
 * @type {ImageMode}
 */
let instance;

class ImageMode {
  /**
   * Construct the object.
   *
   * @param {string} mode - Image mode.
   */
  constructor(mode) {
    this.mode = mode;

    this.optionContainer = document.querySelector(Selectors.IMAGE_MODE_OPTIONS);
    this.options = this.optionContainer.querySelectorAll(Selectors.IMAGE_MODE_OPTION);

    this.onClickOptionHandler = event => this.onClickOption(event);
    this.onImgErrorHandler = event => this.onImgError(event);

    this.setEventListeners();
    this.updateClasses();
  }

  /**
   * Transforms a given url to include the current image mode parameters.
   *
   * @param {string} url - Url string.
   * @param {string} [mode] - Mode to incorporate into the
   * string. Defaults to present mode.
   * @returns {string} Url string.
   */
  static getImageModeUrl(url, mode = instance.mode) {
    if (!url) {
      url = [location.pathname, location.search].join('');
    }
    const sanitizedUrl = this.getSanitizedUrl(url);

    if (mode === Modes.OUTFIT) {
      return sanitizedUrl;
    }

    const prefix = ~sanitizedUrl.indexOf('?') ? '&' : '?';
    return `${sanitizedUrl}${prefix}imgmode=${mode}`;
  }

  /**
   * Returns a given url without image mode parameters.
   *
   * @param {string} [url] - Url string. Defaults to present url.
   * @returns {string} Sanitized url.
   */
  static getSanitizedUrl(url) {
    const parts = url.split('?');
    const query = (parts[1] || '')
      .split(/[?&]/)
      .filter(entry => entry && !IMAGE_MODE_REGEXP.test(entry))
      .reduce((accumulator, entry, index) => `${accumulator}${!index ? '?' : '&'}${entry}`, '');

    return `${parts[0]}${query}`;
  }

  /**
   * Returns the mode based on a given href.
   *
   * @param {string} [href] - Href string. Defaults to current url.
   * @returns {string} Mode.
   */
  static getModeFromHref(href = location.search) {
    const match = href.match(IMAGE_MODE_REGEXP);

    if (match) {
      return match[1];
    }

    return Modes.OUTFIT;
  }

  /**
   * Returns the current mode.
   *
   * @returns {string} Current mode.
   */
  static getCurrentMode() {
    if (instance && instance.mode) {
      return instance.mode;
    }

    return this.getModeFromHref();
  }

  /**
   * Updates the mode by getting the mode from the
   * provided href. This utility function can be used to
   * change the mode prior to manually loading any href.
   *
   * If not used prior to doing a page load request,
   * the href will instead be adjusted to fit the present
   * image mode.
   *
   * @param {string} href - Href used to determine the new image mode.
   */
  static updateModeFromHref(href) {
    const mode = this.getModeFromHref(href);

    instance.setMode(mode, true);
  }

  /**
   * Attaches the image mode to the current view.
   *
   * @param {Node} [target] - Dom node.
   * @returns {ImageMode} Object instance.
   */
  static attach(target = document) {
    const plpQubitImageViewMode =
      document.querySelector('.js-productLister')?.dataset?.plpQubitImageViewMode;
    let mode = plpQubitImageViewMode ? plpQubitImageViewMode : this.getModeFromHref();

    if (instance) {
      mode = instance.mode;
      instance.destroy();
    }

    instance = new ImageMode(mode);
    instance.processImages(target);

    return instance;
  }

  /**
   * Returns a view options object to be used with the mobile facets.
   *
   * @returns {Object} View options.
   */
  static getViewOptions() {
    const { imageSwitch, imageSwitchOutfit, imageSwitchProduct } = window.labels.facetNavigation;

    const { OUTFIT, PRODUCT } = Modes;

    return {
      code: 'images',
      name: imageSwitch,
      facetValues: [
        {
          code: OUTFIT,
          name: imageSwitchOutfit,
          selected: instance.mode === OUTFIT,
          url: this.getImageModeUrl(null, OUTFIT),
        },
        {
          code: PRODUCT,
          name: imageSwitchProduct,
          selected: instance.mode === PRODUCT,
          url: this.getImageModeUrl(null, PRODUCT),
        },
      ],
      isViewOptions: true,
      default: Modes.OUTFIT,
    };
  }

  /**
   * Processes all the images in the view.
   *
   * @param {Node} target - Dom node.
   */
  processImages(target) {
    const images = Array.from(target.querySelectorAll(Selectors.TILE_IMAGE));
    const filtered = {
      immediate: images.filter(img => img.classList.contains(Classes.IMMEDIATE)),
      lazy: images.filter(img => !img.classList.contains(Classes.IMMEDIATE)),
    };

    filtered.lazy.forEach(img => img.addEventListener('error', this.onImgErrorHandler));
    filtered.immediate.forEach(img => {
      const {
        dataset: { failed },
      } = img;

      if (failed) {
        this.fixImage(img);
      } else if (!img.classList.contains(Classes.LOADED)) {
        img.addEventListener('error', this.onImgErrorHandler);
      }
    });

    this.images = images;
  }

  /**
   * Handles image errors.
   *
   * @param {object} event - Event object.
   */
  onImgError(event) {
    const {
      target,
      target: { dataset: { fixed } = {} },
    } = event;

    // TODO: Report broken image URL?

    if (!fixed) {
      this.fixImage(target);
    }
  }

  /**
   * Attempts to fix any images producing an error.
   *
   * @param {Node} img - Image dom element.
   */
  fixImage(img) {
    const sibling = img.parentNode.querySelector(Selectors.SECONDARY_TILE_IMAGE);

    if (sibling) {
      const { className } = img;

      img.className = sibling.className;
      sibling.className = className;
      sibling.srcset = sibling.dataset.srcset;
      sibling.dataset.fixed = 'true';
    }
  }

  /**
   * Sets event listeners.
   */
  setEventListeners() {
    this.options.forEach(option => option.addEventListener('click', this.onClickOptionHandler));
  }

  /**
   * Removes event listeners.
   */
  removeEventListeners() {
    this.options.forEach(option => option.removeEventListener('click', this.onClickOptionHandler));
    this.images.forEach(option => option.removeEventListener('error', this.onImgErrorHandler));
  }

  /**
   * Handles on option click events.
   *
   * @param {object} event - Event object.
   */
  onClickOption(event) {
    const { target } = event;
    const { dataset: { value } = {} } = target;

    document.jq.trigger(AnalyticsEventTypes.VIEW_AS_CLICK, {
      label: target.dataset.value.toLowerCase(),
    });

    this.setMode(value);
  }

  /**
   * Sets the image mode.
   *
   * @param {string} mode - Image mode.
   * @param {boolean} [preventPageLoad] - Whether or not to prevent a page
   * load after changing the image mode.
   */
  setMode(mode, preventPageLoad = false) {
    const plpQubitImageViewMode =
      document.querySelector('.js-productLister')?.dataset?.plpQubitImageViewMode;

    const pushToDatalayer = label => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'abtest.event',
        'abtest.action': 240372,
        'abtest.label': label,
      });
    };

    if (plpQubitImageViewMode === 'product' && mode === 'outfit') {
      pushToDatalayer('click_outfit');
    } else if (plpQubitImageViewMode !== 'product' && mode === 'product') {
      pushToDatalayer('click_product');
    }

    if (this.mode !== mode) {
      this.mode = mode;
      this.updateClasses();

      if (preventPageLoad) {
        return;
      }

      const url = ImageMode.getImageModeUrl();
      const eventData = {
        url,
        scrollTop: window.scrollY,
        immidiateScroll: true,
      };

      if (AppSettings.enablePLPLoadMore) {
        const sections = document.querySelectorAll('[data-page]');
        if (sections.length > 1) {
          const realEstate = (function () {
            const selector = [
              '.js-topNavigation',
              '.productLister-facetsContainer--horizontal',
              '.js-appliedFacets',
            ];
            const elements = document.querySelectorAll(selector.join(','));
            return Math.ceil(
              Array.prototype.reduce.call(
                elements,
                (acc, el) => {
                  return acc + el.getBoundingClientRect().height;
                },
                0
              )
            );
          })();

          const sectionInView = Array.prototype.filter
            .call(
              sections,
              el =>
                el.getBoundingClientRect().bottom >
                realEstate + document.documentElement.clientHeight * 0.2
            )
            .shift();
          const newPage = parseInt(sectionInView.dataset.page, 10);

          const reducibleHeight = sectionInView.jq
            .prevAll()
            .toArray()
            .reduce((acc, el) => {
              return acc + el.jq.height();
            }, 0);
          eventData.url = this.addPageNumberToUrl(eventData.url, newPage);
          eventData.scrollTop = Math.round(
            (window.scrollY || window.pageYOffset) - reducibleHeight
          );
        }
        eventData.clearContainer = true;
      }
      document.jq.trigger(EventTypes.PLP_LOAD_PAGE_REQUEST, eventData);
    }
  }

  addPageNumberToUrl(url, pageNumber) {
    const pageSegment = `page=${pageNumber}`;
    if (/page=/.test(url)) {
      url = url.replace(/page=[0-9]+/, pageSegment);
    } else {
      url = `${url}${/\?/.test(url) ? '&' : '?'}${pageSegment}`;
    }

    if (pageNumber === 0) {
      const newUrl = new URL(window.location.origin + url);
      newUrl.searchParams.delete('page');
      return newUrl.pathname + newUrl.search;
    }
    return url;
  }

  /**
   * Update any classes.
   */
  updateClasses() {
    this.options.forEach(option => {
      const {
        dataset: { value },
      } = option;

      option.classList.toggle(Classes.OPTION_ACTIVE, value === this.mode);
    });
  }

  /**
   * Destroys the object instance.
   */
  destroy() {
    this.removeEventListeners();

    this.optionContainer = null;
    this.options = null;
    this.images = null;

    instance = null;
  }
}

ImageMode.MODES = Modes;

export default ImageMode;
