import $ from 'jquery';
import matchBreakpoint from 'components/utils/matchBreakpoint';
import device from 'components/utils/device';
import EventTypes from 'components/EventTypes';
import transparentGif from 'components/utils/transparentGif';
let posterImage = '';
if (device.osName === device.ANDROID || (device.osName === device.IOS && device.osVersion >= 9)) {
  posterImage = transparentGif;
}

const videoNativeHtml = `<video id="{{id}}" class="js-videoPlayerFrame {{classNames}}" preload="auto" {{#mute}}muted {{#playInBackground}}webkit-playsinline playsinline{{/playInBackground}}{{/mute}} {{#hasNativeControls}}controls{{/hasNativeControls}} {{#autoplay}}autoplay{{/autoplay}} {{#loop}}loop{{/loop}} width="{{width}}" height="{{height}}" poster="${posterImage}">{{#videoSources}}<source data-src="{{url}}" type="{{mimetype}}">{{/videoSources}}</video>`;

export default {
  videoPlayerTemplate: videoNativeHtml,
  videoTypes: ['hd', 'sd', 'mobile'],
  videoFormats: [
    {
      ext: 'mp4',
      mimetype: 'video/mp4',
    },
  ],

  bindReadyEvent() {
    this.$videoContainer.toggleClass('is-paused', !this.playerSettings.autoplay);

    // Fixes IE9/10 something so that VideoControls.js works
    // please do not remove this not so useless listener
    this.$player.on('error', function () {});

    this.$player.on(
      'canplay',
      function () {
        // when autoplay is not supported hide/remove player
        if (this.playerSettings.playInBackground && this.player.autoplay && this.player.paused) {
          this.player.play();
          if (this.player.paused) {
            this.$player.hide().remove();
          }
        }
      }.bind(this)
    );

    // listen for video loadeddata event and dispatch custom event
    this.$player.on(
      'loadeddata',
      function () {
        const eventData = {
          duration: this.player.duration,
          paused: this.player.paused,
          muted: this.player.muted,
          playInBackground: this.playerSettings.playInBackground,
        };

        this.showVideoFrame();

        this.$player
          .trigger(EventTypes.VIDEO_PLAYER_READY)
          .trigger(EventTypes.VIDEO_LOADED_DATA, eventData);
      }.bind(this)
    );

    // this listener seems to be necessary for Firefox and IE
    // to know whether the video player is paused or not
    this.$player.on(
      'playing',
      function () {
        const eventData = {
          paused: this.player.paused,
        };

        this.$player.trigger(EventTypes.VIDEO_PLAYING, eventData);
      }.bind(this)
    );

    // listen for video timeupdate event and dispatch custom event
    this.$player.on(
      'timeupdate',
      function () {
        const eventData = {
          duration: this.player.duration,
          currentTime: this.player.currentTime,
        };

        this.$player.trigger(EventTypes.VIDEO_TIME_UPDATE, eventData);
      }.bind(this)
    );

    // listen for video ended event and dispatch custom event
    this.$player.on(
      'ended',
      function () {
        this.$player.trigger(EventTypes.VIDEO_PLAYBACK_STOPPED);
      }.bind(this)
    );

    // listen for breakpoint change and update source url
    document.jq.on(
      EventTypes.BREAKPOINT_CHANGED,
      function () {
        this.setVideoSource();
        const { $player } = this;
        const { player } = this;
        $.each(this.playerSettings.videoSources, function (i, source) {
          const $source = $player.find(`[type="${source.mimetype}"]`);
          if ($source.attr('src') !== source.url) {
            $source.attr('src', source.url);
            player.load();
          }
        });
      }.bind(this)
    );
  },

  load() {
    this.player.load();
  },

  /**
   * Handle video pause event
   */
  pause() {
    this.togglePlayPause(false);
  },

  /**
   * Handle video play event
   */
  play() {
    this.togglePlayPause(true);
  },

  /**
   * Handle video replay request event
   */
  replay() {
    // to prevent a javascript error when interacting with
    // the video player before it is ready to be used
    try {
      this.player.currentTime = 0;
    } catch (error) {}

    this.play();
  },

  /**
   * Toggle play pause setting
   * @param {Boolean} toggle
   */
  togglePlayPause(toggle) {
    if (toggle) {
      if (this.player.paused) {
        this.player.play();
      }
    } else if (!this.player.paused) {
      this.player.pause();
    }

    let triggerEvent = EventTypes.VIDEO_PLAYBACK_STARTED;
    const eventData = {
      paused: this.player.paused,
    };

    if (!toggle) {
      triggerEvent = EventTypes.VIDEO_PLAYBACK_PAUSED;
    }

    this.$videoContainer.toggleClass('is-paused', !toggle).trigger(triggerEvent, eventData);
  },

  /**
   * Handle the seek request event
   * @param {Event} event
   * @param {Object} eventData
   */
  seek(event, eventData) {
    const time = (eventData.percentage * this.player.duration) / 100;
    this.player.currentTime = time;
  },

  /**
   * Handle video stop event
   */
  stop() {
    this.player.pause();
    this.player.currentTime = 0;
    this.$player.trigger(EventTypes.VIDEO_PLAYBACK_STOPPED);
  },

  /**
   * Handle mute request event
   */
  mute() {
    this.toggleMute(true);
  },

  /**
   * Handle unmute request event
   */
  unmute() {
    this.toggleMute(false);
  },

  /**
   * Toggle mute setting
   * @param {Boolean} toggle
   */
  toggleMute(toggle) {
    this.player.muted = toggle;

    let triggerEvent = EventTypes.VIDEO_MUTED;
    const eventData = {
      muted: this.player.muted,
    };

    if (!toggle) {
      triggerEvent = EventTypes.VIDEO_UNMUTED;
    }

    this.$player.trigger(triggerEvent, eventData);
  },

  setVideoSource() {
    const requestType = matchBreakpoint('small')
      ? 'hd'
      : matchBreakpoint('xsmall')
      ? 'sd'
      : 'mobile';

    this.playerSettings.videoSources = [];

    $.each(
      this.videoTypes,
      function (i, type) {
        if (type === requestType) {
          $.each(
            this.videoFormats,
            function (i, format) {
              const url = this.$videoContainer.attr(`data-video-${type}-${format.ext}`);

              if (url) {
                this.playerSettings.videoSources.push({
                  url,
                  mimetype: format.mimetype,
                });
              }
            }.bind(this)
          );
        }
      }.bind(this)
    );
  },
};
